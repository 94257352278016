import * as React from 'react';
import Animated from 'animated';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import { MdRepeat, MdRepeatOne } from 'react-icons/md';
import {
	FiXSquare,
	FiXCircle,
	FiPlay,
	FiPause,
	FiSkipBack,
	FiSkipForward,
	FiActivity,
} from 'react-icons/fi';

import * as musicActions from './../../store/actions/music';

import GlobalHotkey from './../GlobalHotkey/GlobalHotkey';

import { getTitle, getThumbnailLocation } from '../../libs/link-utils';

import * as s from './MusicPlayerStyle';
import { NoteListWrapper } from './../Dashboard/DashboardStyle';

const AnimatedProgress = Animated.createAnimatedComponent(s.Progress);

class MusicPlayer extends React.PureComponent {
	state = {
		progressValue: new Animated.Value(0),
	};

	componentDidMount() {
		if (
			this.props.musicState.current &&
			this.props.musicState.isPlaying &&
			this.props.musicState.progress &&
			this.props.musicState.progress > 0
		) {
			this.seekTo(this.props.musicState.progress);
		}
	}

	onPreviousClick = () => {
		this.previous();
	};

	onPlayClick = () => {
		this.props.musicActions.play();
	};

	onPauseClick = () => {
		this.props.musicActions.pause();
	};

	onNextClick = () => {
		this.next();
	};

	onRemoveCurrentClick = () => {
		if (!this.props.musicState.current) {
			return;
		}

		this.props.musicActions.removeFromQueue(this.props.musicState.current);
	};

	onClearClick = () => {
		this.props.musicActions.clearQueue();
	};

	onMinimizedToggleClick = () => {
		this.props.musicActions.toggleMinimize();
	};

	onTogglePress = () => {
		if (this.props.musicState.isPlaying) {
			this.props.musicActions.pause();
		} else {
			this.props.musicActions.play();
		}
	};

	onSkipForwardPress = () => {
		this.skipTo(5);
	};

	onSkipBackwardPress = () => {
		this.skipTo(-5);
	};

	onNextPress = () => {
		this.next();
	};

	onPreviousPress = () => {
		this.previous();
	};

	onToggleRepeatClick = () => {
		this.props.musicActions.toggleRepeat();
	};

	onPlayerProgress = info => {
		this.props.musicActions.progress(info.played);

		Animated.timing(this.state.progressValue, {
			toValue: info.played,
			duration: 200,
		}).start();
	};

	onPlayerEnd = () => {
		this.next();
	};

	onPlayerPause = () => {
		this.props.musicActions.pause();
	};

	onPlayerPlay = () => {
		this.props.musicActions.play();
	};

	onFullscreenPress = () => {
		if (!this.player.current) {
			return;
		}

		const domNode = findDOMNode(this.player.current);
		screenfull.request(domNode);
	};

	player = React.createRef();

	seekTo(fraction) {
		if (!this.player.current) {
			return;
		}

		this.player.current.seekTo(fraction);
	}

	skipTo(amount) {
		if (!this.player.current) {
			return;
		}

		const currentTime = this.player.current.getCurrentTime();

		if (!currentTime || !this.player.current) {
			return;
		}

		this.player.current.seekTo(currentTime + amount);
	}

	next() {
		if (
			this.props.musicState.repeat === 'one' ||
			this.props.musicState.queue.length === 1
		) {
			this.seekTo(0);
			return;
		}

		this.props.musicActions.next();
	}

	previous() {
		if (
			this.props.musicState.repeat === 'one' ||
			this.props.musicState.queue.length === 1
		) {
			this.seekTo(0);
			return;
		}

		this.props.musicActions.previous();
	}

	isPlaying(link) {
		if (!this.props.musicState.isPlaying) {
			return false;
		}

		if (!this.props.musicState.current) {
			return false;
		}

		return this.props.musicState.current.id === link.id;
	}

	renderCurrentTrack() {
		if (!this.props.musicState.current) {
			return (
				<s.CurrentTrack>
					<s.CurrentTrackNone>None</s.CurrentTrackNone>
				</s.CurrentTrack>
			);
		}

		return (
			<s.CurrentTrack>
				<s.CurrentTrackImage
					src={getThumbnailLocation(this.props.musicState.current)}
				/>

				<s.CurrentTrackName>
					{getTitle(this.props.musicState.current)}
				</s.CurrentTrackName>
			</s.CurrentTrack>
		);
	}

	renderButtons() {
		return (
			<s.Buttons>
				<s.Button onClick={this.onClearClick}>
					<FiXSquare />
				</s.Button>

				<s.Button onClick={this.onToggleRepeatClick}>
					{this.props.musicState.repeat === 'all' ? (
						<MdRepeat />
					) : (
						<MdRepeatOne />
					)}
				</s.Button>

				<s.Button onClick={this.onPreviousClick}>
					<FiSkipBack />
				</s.Button>

				{!this.props.musicState.isPlaying && (
					<s.Button onClick={this.onPlayClick}>
						<FiPlay />
					</s.Button>
				)}

				{this.props.musicState.isPlaying && (
					<s.Button onClick={this.onPauseClick}>
						<FiPause />
					</s.Button>
				)}

				<s.Button onClick={this.onNextClick}>
					<FiSkipForward />
				</s.Button>
			</s.Buttons>
		);
	}

	renderPlayer() {
		if (!this.props.musicState.current) {
			return null;
		}

		return (
			<s.PlayerContainer>
				<s.PlayerWrapper>
					<ReactPlayer
						ref={this.player}
						playing={this.props.musicState.isPlaying}
						url={this.props.musicState.current.url}
						playsinline
						controls
						onPause={this.onPlayerPause}
						onPlay={this.onPlayerPlay}
						onEnded={this.onPlayerEnd}
						progressInterval={1000}
						volume={null /* 0 -> 1 */}
						muted={false}
						onProgress={this.onPlayerProgress}
						width="100%"
						height="100%"
						config={{
							youtube: {
								playerVars: {
									showinfo: 1,
								},
							},
						}}
					/>
				</s.PlayerWrapper>
			</s.PlayerContainer>
		);
	}

	renderQueueItem = item => {
		return (
			<s.QueueItem key={item.id}>
				<s.QueueItemImage src={getThumbnailLocation(item)} />

				<s.QueueItemTitle>
					{this.isPlaying(item) && (
						<s.QueueItemIsPlaying>
							<FiActivity />
						</s.QueueItemIsPlaying>
					)}

					{getTitle(item)}
				</s.QueueItemTitle>

				<s.QueueItemButtons>
					<s.Button
						onClick={() =>
							this.props.musicActions.removeFromQueue(item)
						}
					>
						<FiXCircle />
					</s.Button>

					<s.Button
						onClick={() => this.props.musicActions.playNow(item)}
					>
						<FiPlay />
					</s.Button>
				</s.QueueItemButtons>
			</s.QueueItem>
		);
	};

	renderQueue() {
		// only render when we have more than one item
		if (
			(this.props.musicState.queue.length <= 1 ||
				this.props.musicState.isMinimized) &&
			!this.props.desktop
		) {
			return null;
		}

		return (
			<s.Queue>
				{this.props.musicState.queue.map(this.renderQueueItem)}
			</s.Queue>
		);
	}

	renderProgress() {
		return (
			<AnimatedProgress
				style={{
					width: this.state.progressValue.interpolate({
						inputRange: [0, 1],
						outputRange: ['0%', '100%'],
					}),
				}}
			/>
		);
	}

	renderHotkeys() {
		return (
			<React.Fragment>
				<GlobalHotkey code="m k" handler={this.onTogglePress} />
				<GlobalHotkey code="m l" handler={this.onSkipForwardPress} />
				<GlobalHotkey code="m j" handler={this.onSkipBackwardPress} />
				<GlobalHotkey code="m n" handler={this.onNextPress} />
				<GlobalHotkey code="m p" handler={this.onPreviousPress} />
				<GlobalHotkey code="m r" handler={this.onToggleRepeatClick} />
				<GlobalHotkey
					code="m m"
					handler={this.onMinimizedToggleClick}
				/>
				<GlobalHotkey code="m c" handler={this.onClearClick} />
				<GlobalHotkey
					code="m f"
					handler={this.onFullscreenPress}
					active={screenfull.enabled}
				/>
			</React.Fragment>
		);
	}

	render() {
		if (
			!this.props.musicState.current &&
			this.props.musicState.queue.length === 0
		) {
			return null;
		}

		const classNames = [];

		if (this.props.musicState.isMinimized) {
			classNames.push('is-minimized');
		}

		return (
			<NoteListWrapper desktop={this.props.desktop}>
				<s.Container className={classNames.join(' ')}>
					{this.renderHotkeys()}

					<s.Wrapper>
						{this.renderPlayer()}
						{this.renderQueue()}

						<s.Info>
							{this.renderCurrentTrack()}
							{this.renderButtons()}
							{this.renderProgress()}
						</s.Info>
					</s.Wrapper>
				</s.Container>
			</NoteListWrapper>
		);
	}
}

export default connect(
	state => ({
		musicState: state.music,
	}),
	dispatch => ({
		musicActions: bindActionCreators(musicActions, dispatch),
	}),
)(MusicPlayer);

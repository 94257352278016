import styled, { css, keyframes } from 'styled-components/macro';
import { mix } from 'polished';

export const Container = styled.li`
	border-bottom: 1px solid ${props => props.theme.borderColor};
	padding: 1.2em 0;
	position: relative;
	display: grid;
	grid-column-gap: 1em;
	grid-template-columns: 1fr auto;
	grid-template-areas: 'body image' 'meta image';

	${props =>
		props.archived
			? css`
					filter: grayscale(0.7);
					color: ${props =>
						mix(
							0.6,
							props.theme.textColor,
							props.theme.backgroundColor,
						)};
					transition: filter 0.3s, color 0.3s;

					&:hover {
						filter: grayscale(0) blur(0);
					}
			  `
			: ''};

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 1em;
		bottom: 1em;
		left: -1em;
		width: 3px;
		background: transparent;
		transition: background 0.2s;
	}

	${props =>
		props.selected
			? css`
					filter: none;
					color: inherit;

					&::before {
						background: ${props => props.theme.accentColor};
					}
			  `
			: ''};
`;

function emptyNoteContainerDesktop(props) {
	if (props.desktop) {
		return css`
			top: 0;
		`;
	}

	return css`
		top: auto;
		bottom: 0;
		order: 1;
		padding-bottom: 1em;

		> div {
			border-bottom: none;
			border-top: 1px solid ${props => props.theme.borderColor};
		}
	`;
}

const isLoadingAnimation = keyframes`
	0% {
		width: 0%;
		left: 0;
	}

	50% {
		width: 50%;
	}

	100% {
		width: 0%;
		left: 100%;
	}
`;

function isLoadingLocation(props) {
	if (props.createNote) {
		return css`
			bottom: -1px;

			@media (max-width: 1000px) {
				bottom: auto;
				top: -1px;
			}
		`;
	}

	return css`
		bottom: -1px;
	`;
}

function isLoading(props) {
	if (!props.isLoading) {
		return '';
	}

	return css`
		&:after {
			content: '';
			display: block;
			background: ${props =>
				mix(0.6, props.theme.accentColor, props.theme.backgroundColor)};
			position: absolute;
			left: 0;
			top: 0;
			width: 0%;
			height: 1px;
			animation-name: ${isLoadingAnimation};
			animation-delay: 0.1s;
			animation-duration: 1.7s;
			animation-iteration-count: infinite;
			animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
		}
	`;
}

export const Loader = styled.div`
	overflow: hidden;
	position: absolute;
	left: 0;
	width: 100%;
	height: 1px;
	${isLoadingLocation};

	${isLoading}
`;

export const EmptyNoteContainer = styled.li`
	position: sticky;

	// same as global style
	background-color: ${props => props.theme.backgroundColor};
	transition: background-color 0.4s, color 0.4s;
	z-index: 3;

	${emptyNoteContainerDesktop};
`;

// export const Content = styled.div`
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: stretch;
// 	align-items: flex-start;
// `;

export const BlurBackgroundCanvas = styled.canvas`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
`;

function isNsfw(props) {
	if (!props.nsfw) {
		return '';
	}

	return css`
		filter: blur(${props.selected ? '0' : '3px'});
		transition: filter 0.2s;

		&:hover {
			filter: blur(0);
		}
	`;
}

export const Image = styled.img`
	width: 4em;
	height: 4em;
	display: block;
	object-fit: cover;

	${isNsfw};
`;

function firstImageWrapperDesktop(props) {
	if (props.desktop) {
		return css`
			width: 5em;
			height: 5em;
			flex-basis: 5em;

			${Image} {
				width: 5em;
				height: 5em;
			}
		`;
	}

	return css`
		width: 10em;
		height: 10em;
		flex-basis: 10em;

		${Image} {
			width: 10em;
			height: 10em;
		}
	`;
}

export const FirstImageWrapper = styled.div`
	grid-area: image;
	position: relative;

	// make sure we always render these with the given width
	flex-grow: 0;
	flex-shrink: 0;

	${firstImageWrapperDesktop};
`;

export const Body = styled.div`
	grid-area: body;
	text-align: left;
	overflow-wrap: break-word;

	// use the maximum amount of space, without going outside the container
	min-width: 0;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;

	a {
		color: inherit;
	}
`;

export const ImagesWrapper = styled.ul`
	list-style: none;
	margin-top: 1em;
	display: grid;
	grid-gap: 1em;
	grid-template-columns: repeat(auto-fill, minmax(4em, 1fr));
`;

export const LinkWrapper = styled.li`
	width: 4em;
	height: 4em;
	position: relative;
`;

export const MusicWrapper = styled.div`
	position: relative;
`;

export const MusicActions = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const MusicAction = styled.button`
	apperance: none;
	border: none;
	background: none;
	border-radius: 0;
	cursor: pointer;
	font-size: 1.5em;
	color: #fff;

	svg {
		display: block;
		filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.7));
	}
`;

export const Meta = styled.div`
	grid-area: meta;
	margin-top: 1em;
	display: flex;
	font-size: 0.8em;
	justify-content: space-between;

	@media (max-width: 1000px) {
		font-size: 1em;
	}
`;

export const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const CreatedAt = styled.span`
	color: ${props =>
		mix(0.4, props.theme.textColor, props.theme.backgroundColor)};
`;

export const ActionNotice = styled.button`
	apperance: none;
	border: none;
	background: none;
	border-radius: 0;
	color: ${props =>
		mix(0.4, props.theme.textColor, props.theme.backgroundColor)};
	text-transform: lowercase;
	cursor: pointer;
	transition: color 0.1s;
	margin-left: 1em;
	font-size: 1em;
	letter-spacing: 0.3px;
	font-weight: 300;

	&:first-child {
		margin-left: 0;
	}

	${Container}:hover & {
		color: ${props =>
			mix(0.6, props.theme.textColor, props.theme.backgroundColor)};
	}
`;

export const Action = styled.button`
	apperance: none;
	border: none;
	background: none;
	border-radius: 0;
	color: ${props =>
		mix(0.4, props.theme.textColor, props.theme.backgroundColor)};
	text-transform: lowercase;
	cursor: pointer;
	transition: color 0.1s;
	margin-left: 1em;
	font-size: 1em;
	letter-spacing: 0.3px;
	font-weight: 300;
	display: flex;
	align-items: center;

	&:first-child {
		margin-left: 0;
	}

	&:hover {
		text-decoration: underline;
	}

	// the icon
	> svg {
		position: relative;
		top: 1px;
		margin-right: 0.25em;
	}

	${Container}:hover & {
		color: ${props =>
			mix(0.6, props.theme.textColor, props.theme.backgroundColor)};
	}
`;

import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import * as serviceWorker from './service-worker-registration';

import store, { persistor } from './store';

import App from './components/App/App';

import './index.css';

class Wrapper extends React.Component {
	showLoading() {
		return <div>We're loading</div>;
	}

	render() {
		return (
			<Provider store={store}>
				<PersistGate loading={this.showLoading()} persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		);
	}
}

const root = document.getElementById('root');

if (root) {
	ReactDOM.render(<Wrapper />, root);
}

// serviceWorker.register();
serviceWorker.unregister();

import { takeLatest, select, all, put } from 'redux-saga/effects';

import {
	NAVIGATION_NEXT,
	NAVIGATION_PREVIOUS,
	NAVIGATION_NEXT_LIST,
	NAVIGATION_PREVIOUS_LIST,
	NAVIGATION_FIRST,
	NAVIGATION_LAST,
	set as navigationSet,
	setList as navigationSetList,
	closeList as navigationCloseList,
} from './../actions/navigation';

import { NOTES_LIST_SUCCESS, NOTES_CLOSE_LIST } from './../actions/notes';

const listIdsSelector = state => Object.keys(state.navigation.selected);
const currentListIdSelector = state => state.navigation.selectedList;
const currentSelectedSelector = state =>
	state.navigation.selected[state.navigation.selectedList];
const currentNotesListSelector = state =>
	state.notes[state.navigation.selectedList].notes;
const notesListSelector = (state, listId) => state.notes[listId].notes;

function nextId(list, currentId) {
	let id = null;
	let currentIdExists = false;

	if (!currentId) {
		id = list[0];
	} else {
		for (let i = 0, l = list.length; i < l; i++) {
			if (list[i] === currentId) {
				currentIdExists = true;
				id = list[i + 1];
				break;
			}
		}

		if (!id) {
			if (currentIdExists) {
				id = list[list.length - 1];
			} else {
				id = list[0];
			}
		}
	}

	return id || null;
}

function previousId(list, currentId) {
	let id = null;

	if (!currentId) {
		id = list[0];
	} else {
		for (let i = 0, l = list.length; i < l; i++) {
			if (list[i] === currentId) {
				break;
			}

			id = list[i];
		}

		if (!id) {
			id = list[0];
		}
	}

	return id || null;
}

function* next() {
	try {
		const currentSelected = yield select(currentSelectedSelector);
		const list = yield select(currentNotesListSelector);

		const listId = yield select(currentListIdSelector);
		const noteId = nextId(list, currentSelected);

		yield put(navigationSet(listId, noteId));
	} catch (e) {
		console.log(e);
	}
}

function* previous() {
	try {
		const currentSelected = yield select(currentSelectedSelector);
		const list = yield select(currentNotesListSelector);

		const listId = yield select(currentListIdSelector);
		const noteId = previousId(list, currentSelected);

		yield put(navigationSet(listId, noteId));
	} catch (e) {
		console.log(e);
	}
}

function* nextList() {
	try {
		const currentListId = yield select(currentListIdSelector);
		const list = yield select(listIdsSelector);

		const listId = nextId(list, currentListId);

		yield put(navigationSetList(listId));
	} catch (e) {
		console.log(e);
	}
}

function* previousList() {
	try {
		const currentListId = yield select(currentListIdSelector);
		const list = yield select(listIdsSelector);

		const listId = previousId(list, currentListId);

		yield put(navigationSetList(listId));
	} catch (e) {
		console.log(e);
	}
}

function* first() {
	try {
		const listId = yield select(currentListIdSelector);
		const list = yield select(currentNotesListSelector);
		const noteId = list[0];

		if (noteId) {
			yield put(navigationSet(listId, noteId));
		}
	} catch (e) {
		console.log(e);
	}
}

function* last() {
	try {
		const listId = yield select(currentListIdSelector);
		const list = yield select(currentNotesListSelector);
		const noteId = list[list.length - 1];

		if (noteId) {
			yield put(navigationSet(listId, noteId));
		}
	} catch (e) {
		console.log(e);
	}
}

function* list({ payload: { listId } }) {
	try {
		const list = yield select(notesListSelector, listId);
		const noteId = list[0];

		yield put(navigationSet(listId, noteId));
	} catch (e) {
		console.log(e);
	}
}

function* closeList({ payload: { listId } }) {
	try {
		const currentListId = yield select(currentListIdSelector);

		if (currentListId === listId) {
			yield previousList();
		}

		yield put(navigationCloseList(listId));
	} catch (e) {
		console.log(e);
	}
}

export default function* navigationSaga() {
	yield all([
		takeLatest(NAVIGATION_NEXT, next),
		takeLatest(NAVIGATION_PREVIOUS, previous),
		takeLatest(NAVIGATION_NEXT_LIST, nextList),
		takeLatest(NAVIGATION_PREVIOUS_LIST, previousList),
		takeLatest(NAVIGATION_FIRST, first),
		takeLatest(NAVIGATION_LAST, last),
		takeLatest(NOTES_LIST_SUCCESS, list),
		takeLatest(NOTES_CLOSE_LIST, closeList),
	]);
}

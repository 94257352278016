import styled, { css } from 'styled-components/macro';

export const BlurBackgroundCanvas = styled.canvas`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
`;

function isNsfw(props) {
	if (!props.nsfw) {
		return '';
	}

	return css`
		filter: blur(6px);
		transition: filter 0.2s;

		&:hover {
			filter: blur(0);
		}
	`;
}

function isContain(props) {
	if (!props.$contain) {
		return '';
	}

	return css`
		object-fit: contain;
	`;
}

export const Image = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	object-fit: cover;

	${isNsfw};
	${isContain};
`;

import {
	SCROLL_TO_TOP,
	SCROLL_HALVE_UP,
	SCROLL_HALVE_DOWN,
	SCROLL_UP,
	SCROLL_DOWN,
} from './../actions/scroll';

const listeners = {};

export default function scrollListener(listId, callback) {
	listeners[listId] = callback;

	return () => {
		delete listeners[listId];
	};
}

function broadcastScroll(listId, top) {
	if (listeners[listId]) {
		listeners[listId](top);
	}
}

export function middleware() {
	return next => action => {
		const returnValue = next(action);

		if (action.type === SCROLL_TO_TOP) {
			broadcastScroll(action.payload.listId, -1e9);
		} else if (action.type === SCROLL_HALVE_UP) {
			broadcastScroll(action.payload.listId, -window.innerHeight / 2);
		} else if (action.type === SCROLL_HALVE_DOWN) {
			broadcastScroll(action.payload.listId, window.innerHeight / 2);
		} else if (action.type === SCROLL_UP) {
			broadcastScroll(action.payload.listId, -(window.innerHeight * 0.9));
		} else if (action.type === SCROLL_DOWN) {
			broadcastScroll(action.payload.listId, window.innerHeight * 0.9);
		}

		return returnValue;
	};
}

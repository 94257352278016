import * as React from 'react';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import update from 'immutability-helper';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import {
	FiArchive,
	FiTrash2,
	FiEdit,
	FiXSquare,
	FiSave,
	FiFilePlus,
	FiPlay,
} from 'react-icons/fi';

import { TiPinOutline, TiPin } from 'react-icons/ti';

import * as musicActions from './../../store/actions/music';
import * as navigationActions from './../../store/actions/navigation';
import * as notesActions from './../../store/actions/notes';
import notesArchive from './../../store/units/notes-archive';
import notesPin from './../../store/units/notes-pin';
import notesClearReminder from './../../store/units/notes-clear-reminder';
import notesDelete from './../../store/units/notes-delete';
import notesEdit from './../../store/units/notes-edit';
import notesSetReminder from './../../store/units/notes-set-reminder';
import notesUnarchive from './../../store/units/notes-unarchive';
import notesUnpin from './../../store/units/notes-unpin';

import Blur from './Blur';
import Body from './../Body/Body';
import Editor from './../Editor/Editor';
import GlobalHotkey from './../GlobalHotkey/GlobalHotkey';
import Lightbox from './../Lightbox/Lightbox';

import { makeNoteWithPlainText, isNsfw } from './../../libs/note-utils';
import { isMusic, getThumbnailLocation } from '../../libs/link-utils';
import {
	biggestThumbnail,
	getClosestThumbnail,
} from './../../libs/attachment-utils';
import ListIdContext from './../../contexts/ListIdContext';

import * as s from './NoteStyle';

const THUMBNAILABLE_TYPES = ['IMAGE', 'VIDEO'];

function isThumbnailable(link) {
	return isMusic(link) || THUMBNAILABLE_TYPES.indexOf(link.kind) !== -1;
}

class Note extends React.Component {
	static contextType = ListIdContext;

	static defaultProps = {
		small: false,
	};

	state = {
		isEditing: false,
		isDatePickerOpen: false,
	};

	editor = null;
	noteRef = React.createRef();
	lightboxRef = React.createRef();

	componentDidUpdate(prevProps) {
		if (this.props.selected && !prevProps.selected) {
			if (this.noteRef.current) {
				const boundingBox = this.noteRef.current.getBoundingClientRect();
				const viewportHeight = window.innerHeight;

				// ignores music player height
				const fixedHeaderHeight = 52;
				const topInView =
					boundingBox.top > fixedHeaderHeight &&
					boundingBox.top < viewportHeight;
				const bottomInView =
					boundingBox.bottom > fixedHeaderHeight &&
					boundingBox.bottom < viewportHeight;

				if ((!topInView || !bottomInView) && this.noteRef.current) {
					this.noteRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
						inline: 'nearest',
					});
				}
			}
		}

		// close lightbox when moving away from note
		if (!this.props.selected && prevProps.selected) {
			if (this.lightboxRef.current) {
				this.lightboxRef.current.close();
			}
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		// we need to do this by hand to prevent re-rendering on reference changes
		return (
			!isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
		);
	}

	onClick = () => {
		this.props.onClick(this.props.note);
	};

	onArchiveClick = () => {
		if (this.props.note.archived_at) {
			this.props.notesUnarchive(this.props.note);
		} else {
			this.props.notesArchive(this.props.note);
		}
	};

	onDeleteClick = () => {
		if (!window.confirm('Sure?')) {
			return;
		}

		this.props.notesDelete(this.props.note);
	};

	onEditPress = (_, ev) => {
		ev.stopPropagation();
		ev.preventDefault();

		this.setState({
			isEditing: true,
		});
	};

	onEditClick = () => {
		this.setState({
			isEditing: true,
		});
	};

	onCancelClick = () => {
		this.cancelSave();
	};

	onSaveClick = () => {
		if (!this.editor) {
			return;
		}

		this.editor.triggerSave();
	};

	onEditorSave = body => {
		const note = update(this.props.note, {
			body: {
				$set: body,
			},
		});

		this.props.notesEdit(note);

		this.setState({
			isEditing: false,
		});
	};

	onEditorEscape = () => {
		this.cancelSave();
	};

	onPinnedClick = () => {
		if (this.props.note.pinned_at) {
			this.props.notesUnpin(this.props.note);
		} else {
			this.props.notesPin(this.props.note);
		}
	};

	onReminderClick = () => {
		this.setState(state => ({
			isDatePickerOpen: !state.isDatePickerOpen,
		}));
	};

	onReminderClose = () => {
		this.setState({
			isDatePickerOpen: false,
		});
	};

	onReminderClearClick = () => {
		this.props.notesClearReminder(this.props.note);

		this.setState({
			isDatePickerOpen: false,
		});
	};

	onDatePickerChange = at => {
		this.setState({
			isDatePickerOpen: false,
		});

		this.props.notesSetReminder(this.props.note, at);
	};

	onEnterClick = () => {
		this.doPrimaryAction();
	};

	onHashtagPress = combo => {
		const index = parseInt(combo, 10) - 1 || 0;

		// sort by location in the body
		const topics = sortBy(this.props.note.topics, topic => {
			return this.props.note.body.indexOf(topic.title);
		});

		const topic = topics[index];

		if (!topic) {
			return;
		}

		if (combo.indexOf('a ') !== -1) {
			this.props.notesActions.append(this.context, ` #${topic.title}`);

			return;
		}

		this.props.notesActions.setOrCreateList(
			this.context,
			makeNoteWithPlainText(`#${topic.title} `),
			combo.indexOf('shift') !== -1,
		);
	};

	onLinkPress = combo => {
		const index = parseInt(combo, 10) - 1;

		// sort by location in the body
		const links = sortBy(this.props.note.links, link => {
			return this.props.note.body.indexOf(link.link);
		});

		const link = links[index];

		if (!link) {
			return;
		}

		window.open(link.url, '_blank');
	};

	cancelSave() {
		this.setState({
			isEditing: false,
		});
	}

	getFirstImage() {
		if (this.props.small) {
			return null;
		}

		const images = this.getAllImages();
		return images[0] || null;
	}

	getRemainingImages() {
		const images = this.getAllImages();

		if (!this.props.small) {
			images.shift();
		}

		return images;
	}

	getAllImages() {
		return filter(this.props.note.links, isThumbnailable);
	}

	getAllMusic() {
		return filter(this.props.note.links, isMusic);
	}

	doPrimaryAction() {
		const link = this.getFirstImage();

		if (!link) {
			return;
		}

		if (isMusic(link)) {
			this.props.musicActions.playNow(link);
			return;
		}

		if (isThumbnailable(link)) {
			if (this.lightboxRef.current) {
				this.lightboxRef.current.toggle();
			}

			return;
		}
	}

	isLoading() {
		return (
			this.props.notesArchiveState.isLoading[this.props.note.id] ||
			this.props.notesUnarchiveState.isLoading[this.props.note.id] ||
			this.props.notesPinState.isLoading[this.props.note.id] ||
			this.props.notesUnpinState.isLoading[this.props.note.id] ||
			this.props.notesEditState.isLoading[this.props.note.id] ||
			this.props.notesDeleteState.isLoading[this.props.note.id] ||
			this.props.notesSetReminderState.isLoading[this.props.note.id] ||
			this.props.notesClearReminderState.isLoading[this.props.note.id]
		);
	}

	renderFirstImage() {
		const firstImage = this.getFirstImage();

		if (!firstImage) {
			return null;
		}

		return (
			<s.FirstImageWrapper desktop={this.props.desktop}>
				{this.renderLink(firstImage, true)}
			</s.FirstImageWrapper>
		);
	}

	renderBody() {
		if (
			!this.state.isEditing &&
			!this.props.notesEditState.isLoading[this.props.note.id]
		) {
			return (
				<s.Body>
					<Body text={this.props.note.body} />
				</s.Body>
			);
		}

		const copiedNote = {
			...this.props.note,
		};

		return (
			<s.Body>
				<Editor
					ref={ref => {
						this.editor = ref;
					}}
					readOnly={!this.state.isEditing}
					onSave={this.onEditorSave}
					// onChange={this.onEditorChange}
					onEscape={this.onEditorEscape}
					note={copiedNote}
					placeholder="Please enter some text&hellip;"
					emptyOnCreate={false}
				/>
			</s.Body>
		);
	}

	renderLink = (link, first = false) => {
		switch (link.kind) {
			case 'IMAGE':
				return this.renderImage(link, first);

			case 'VIDEO':
				return this.renderVideo(link, first);

			default:
				switch (true) {
					case isMusic(link):
						return this.renderMusic(link, first);

					default:
						// noop
						break;
				}

				break;
		}
	};

	renderImage(link, first) {
		if (!link.attachment) {
			return null;
		}

		return (
			<Lightbox
				imageSrc={biggestThumbnail(link.attachment)}
				ref={first ? this.lightboxRef : null}
			>
				<Blur blurHash={link.attachment?.blur_hash} />

				<s.Image
					src={getClosestThumbnail(link.attachment, 100)}
					alt=""
					nsfw={isNsfw(this.props.note)}
					selected={this.props.selected}
				/>
			</Lightbox>
		);

		// return (
		// 	<Lightbox
		// 		imageSrc={link.medium.big}
		// 		ref={first ? this.lightboxRef : null}
		// 	>
		// 		<s.Image src={link.medium.thumbnail} alt="" />
		// 	</Lightbox>
		// );
	}

	renderMusic(link, first) {
		const src = getThumbnailLocation(link);

		const addToQueue = () => this.props.musicActions.addToQueue(link);
		const playNow = () => this.props.musicActions.playNow(link);

		return (
			<s.MusicWrapper>
				<GlobalHotkey
					code="q"
					handler={addToQueue}
					active={
						this.props.isSelectedList &&
						this.props.selected &&
						first
					}
				/>

				<s.Image src={src} alt="" />

				<s.MusicActions>
					<s.MusicAction onClick={addToQueue}>
						<FiFilePlus />
					</s.MusicAction>

					<s.MusicAction onClick={playNow}>
						<FiPlay />
					</s.MusicAction>
				</s.MusicActions>
			</s.MusicWrapper>
		);
	}

	renderVideo(link, first) {
		if (!link.attachment) {
			return null;
		}

		const src = getClosestThumbnail(link.attachment, 100);
		const videoSrc = link.attachment?.url;

		return (
			<Lightbox
				videoSrc={videoSrc}
				posterSrc={src}
				ref={first ? this.lightboxRef : null}
				muted={this.props.clientSettingsState.muted}
			>
				<Blur blurHash={link.attachment?.blur_hash} />
				<s.Image
					src={src}
					alt=""
					nsfw={isNsfw(this.props.note)}
					selected={this.props.selected}
				/>
				<FiPlay />
			</Lightbox>
		);
	}

	renderImages() {
		const images = this.getRemainingImages();

		if (images.length === 0) {
			return null;
		}

		return (
			<s.ImagesWrapper>
				{images.map(link => (
					<s.LinkWrapper key={link.id}>
						{this.renderLink(link)}
					</s.LinkWrapper>
				))}
			</s.ImagesWrapper>
		);
	}

	renderDeleteButton() {
		return (
			<s.Action onClick={this.onDeleteClick}>
				<FiTrash2 />
			</s.Action>
		);
	}

	renderArchiveButton() {
		const label = this.props.note.archived_at ? 'Unarchive' : 'Archive';

		return (
			<s.Action onClick={this.onArchiveClick} title={label}>
				<FiArchive />
			</s.Action>
		);
	}

	renderEditButtons() {
		if (this.state.isEditing) {
			return (
				<React.Fragment>
					<s.Action onClick={this.onCancelClick}>
						<FiXSquare />
					</s.Action>
					<s.Action onClick={this.onSaveClick}>
						<FiSave />
					</s.Action>
				</React.Fragment>
			);
		}

		return (
			<s.Action onClick={this.onEditClick}>
				<FiEdit />
			</s.Action>
		);
	}

	renderPinnedButton() {
		const label = this.props.note.pinned_at ? 'Unpin' : 'Pin';

		return (
			<s.Action onClick={this.onPinnedClick} title={label}>
				{this.props.note.pinned_at ? <TiPin /> : <TiPinOutline />}
			</s.Action>
		);
	}

	renderReminderButton() {
		return null;
		// return (
		// 	<React.Fragment>
		// 		<DatePicker
		// 			value={
		// 				this.props.note.reminder && this.props.note.reminder.at
		// 			}
		// 			onChange={this.onDatePickerChange}
		// 			onClose={this.onReminderClose}
		// 			isOpen={this.state.isDatePickerOpen}
		// 		/>

		// 		{this.props.note.reminder && (
		// 			<s.Action onClick={this.onReminderClearClick}>
		// 				<FiBellOff />
		// 			</s.Action>
		// 		)}

		// 		<s.Action onClick={this.onReminderClick}>
		// 			<FiBell />
		// 		</s.Action>
		// 	</React.Fragment>
		// );
	}

	renderIsLoading() {
		if (!this.isLoading()) {
			return null;
		}

		return <s.ActionNotice>Loading&hellip;</s.ActionNotice>;
	}

	renderCreatedAt() {
		if (!this.props.note.created_at) {
			return null;
		}

		return (
			<s.CreatedAt>
				{format(this.props.note.created_at, "dd-MM-yyyy 'at' HH:mm")}
			</s.CreatedAt>
		);
	}

	renderMeta() {
		return (
			<s.Meta>
				{this.renderCreatedAt()}

				<s.Actions>
					{this.renderIsLoading()}
					{this.renderPinnedButton()}
					{this.renderReminderButton()}
					{this.renderEditButtons()}
					{this.renderDeleteButton()}
					{this.renderArchiveButton()}
				</s.Actions>
			</s.Meta>
		);
	}

	renderHotkeys() {
		if (!this.props.selected) {
			return null;
		}

		return (
			<React.Fragment>
				<GlobalHotkey code="e" handler={this.onArchiveClick} />
				<GlobalHotkey code="p" handler={this.onPinnedClick} />
				<GlobalHotkey code="w" handler={this.onEditPress} />
				<GlobalHotkey code="&" handler={this.onDeleteClick} />
				<GlobalHotkey code="enter" handler={this.onEnterClick} />

				<GlobalHotkey code="h" handler={this.onHashtagPress} />
				<GlobalHotkey code="1 h" handler={this.onHashtagPress} />
				<GlobalHotkey code="2 h" handler={this.onHashtagPress} />
				<GlobalHotkey code="3 h" handler={this.onHashtagPress} />
				<GlobalHotkey code="4 h" handler={this.onHashtagPress} />
				<GlobalHotkey code="5 h" handler={this.onHashtagPress} />
				<GlobalHotkey code="6 h" handler={this.onHashtagPress} />
				<GlobalHotkey code="7 h" handler={this.onHashtagPress} />
				<GlobalHotkey code="8 h" handler={this.onHashtagPress} />
				<GlobalHotkey code="9 h" handler={this.onHashtagPress} />

				<GlobalHotkey code="shift+h" handler={this.onHashtagPress} />
				<GlobalHotkey code="1 shift+h" handler={this.onHashtagPress} />
				<GlobalHotkey code="2 shift+h" handler={this.onHashtagPress} />
				<GlobalHotkey code="3 shift+h" handler={this.onHashtagPress} />
				<GlobalHotkey code="4 shift+h" handler={this.onHashtagPress} />
				<GlobalHotkey code="5 shift+h" handler={this.onHashtagPress} />
				<GlobalHotkey code="6 shift+h" handler={this.onHashtagPress} />
				<GlobalHotkey code="7 shift+h" handler={this.onHashtagPress} />
				<GlobalHotkey code="8 shift+h" handler={this.onHashtagPress} />
				<GlobalHotkey code="9 shift+h" handler={this.onHashtagPress} />

				<GlobalHotkey code="a h" handler={this.onHashtagPress} />
				<GlobalHotkey code="1 a h" handler={this.onHashtagPress} />
				<GlobalHotkey code="2 a h" handler={this.onHashtagPress} />
				<GlobalHotkey code="3 a h" handler={this.onHashtagPress} />
				<GlobalHotkey code="4 a h" handler={this.onHashtagPress} />
				<GlobalHotkey code="5 a h" handler={this.onHashtagPress} />
				<GlobalHotkey code="6 a h" handler={this.onHashtagPress} />
				<GlobalHotkey code="7 a h" handler={this.onHashtagPress} />
				<GlobalHotkey code="8 a h" handler={this.onHashtagPress} />
				<GlobalHotkey code="9 a h" handler={this.onHashtagPress} />

				<GlobalHotkey code="l" handler={this.onLinkPress} />
				<GlobalHotkey code="1 l" handler={this.onLinkPress} />
				<GlobalHotkey code="2 l" handler={this.onLinkPress} />
				<GlobalHotkey code="3 l" handler={this.onLinkPress} />
				<GlobalHotkey code="4 l" handler={this.onLinkPress} />
				<GlobalHotkey code="5 l" handler={this.onLinkPress} />
				<GlobalHotkey code="6 l" handler={this.onLinkPress} />
				<GlobalHotkey code="7 l" handler={this.onLinkPress} />
				<GlobalHotkey code="8 l" handler={this.onLinkPress} />
				<GlobalHotkey code="9 l" handler={this.onLinkPress} />
			</React.Fragment>
		);
	}

	render() {
		return (
			<s.Container
				selected={this.props.selected}
				archived={!!this.props.note.archived_at}
				ref={this.noteRef}
				onClick={this.onClick}
				data-id={this.props.note.id}
			>
				{this.renderHotkeys()}

				{this.renderBody()}
				{this.renderFirstImage()}

				{this.renderImages()}
				{this.renderMeta()}

				<s.Loader isLoading={this.isLoading()} />
			</s.Container>
		);
	}
}

export default connect(
	state => ({
		notesArchiveState: state.notesArchive,
		notesUnarchiveState: state.notesUnarchive,
		notesPinState: state.notesPin,
		notesUnpinState: state.notesUnpin,
		notesEditState: state.notesEdit,
		notesDeleteState: state.notesDelete,
		notesSetReminderState: state.notesSetReminder,
		notesClearReminderState: state.notesClearReminder,
		clientSettingsState: state.clientSettings,
	}),
	dispatch => ({
		notesActions: bindActionCreators(notesActions, dispatch),
		musicActions: bindActionCreators(musicActions, dispatch),
		navigationActions: bindActionCreators(navigationActions, dispatch),
		notesEdit: bindActionCreators(notesEdit, dispatch),
		notesDelete: bindActionCreators(notesDelete, dispatch),
		notesArchive: bindActionCreators(notesArchive, dispatch),
		notesUnarchive: bindActionCreators(notesUnarchive, dispatch),
		notesPin: bindActionCreators(notesPin, dispatch),
		notesUnpin: bindActionCreators(notesUnpin, dispatch),
		notesSetReminder: bindActionCreators(notesSetReminder, dispatch),
		notesClearReminder: bindActionCreators(notesClearReminder, dispatch),
	}),
)(Note);

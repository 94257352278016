import { denormalize } from 'normalizr';
import { notesSchemaList } from './../schemas/notes';

export function notesListSelector(id, state) {
	return {
		notesList: denormalize(
			(state.notes[id] || {}).notes || [],
			notesSchemaList,
			state.byId,
		),
	};
}

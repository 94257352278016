function getYoutubeInfo(link) {
	if (!link) {
		return null;
	}

	let url;

	try {
		url = new URL(link.url);
	} catch {
		return null;
	}

	let youtubeId;

	if (url.hostname === 'www.youtube.com') {
		youtubeId = url.searchParams.get('v');
	} else if (url.hostname === 'youtu.be') {
		youtubeId = url.pathname.replace(/^\//, '').replace(/\/.*/, '');
	} else {
		return null;
	}

	return {
		title: String(link.title).replace(/ - YouTube$/, ''),
		thumbnailLocation: `https://i.ytimg.com/vi/${youtubeId}/hqdefault.jpg`,
	};
}

function getSoundcloudInfo(link) {
	if (!link) {
		return null;
	}

	let url;

	try {
		url = new URL(link.url);
	} catch {
		return null;
	}

	if (url.hostname !== 'soundcloud.com') {
		return null;
	}

	return {
		title: String(link.title).replace(
			/ \| Free Listening on SoundCloud$/,
			'',
		),
	};
}

function getMixcloudInfo(link) {
	if (!link) {
		return null;
	}

	let url;

	try {
		url = new URL(link.url);
	} catch {
		return null;
	}

	if (url.hostname !== 'www.mixcloud.com') {
		return null;
	}

	return {
		title: String(link.title).replace(/ \| Mixcloud$/, ''),
	};
}

function getLinkInfo(link) {
	if (!link) {
		return null;
	}

	const youtubeInfo = getYoutubeInfo(link);

	if (youtubeInfo) {
		return youtubeInfo;
	}

	const soundcloudInfo = getSoundcloudInfo(link);

	if (soundcloudInfo) {
		return soundcloudInfo;
	}

	const mixcloudInfo = getMixcloudInfo(link);

	if (mixcloudInfo) {
		return mixcloudInfo;
	}

	return null;
}

export function isYoutube(link) {
	return !!getYoutubeInfo(link);
}

export function isSoundcloud(link) {
	return !!getSoundcloudInfo(link);
}

export function isMixcloud(link) {
	return !!getMixcloudInfo(link);
}

export function isMusic(link) {
	return isYoutube(link) || isSoundcloud(link) || isMixcloud(link);
}

export function getTitle(link) {
	const info = getLinkInfo(link);

	return info?.title || link?.title || '';

	// if (link.metatype === 'soundcloud') {
	// 	return String(link.title).replace(
	// 		/ \| Free Listening on SoundCloud$/,
	// 		'',
	// 	);
	// }

	// if (link.metatype === 'mixcloud') {
	// 	return String(link.title).replace(/ \| Mixcloud$/, '');
	// }

	// return link.title;
}

export function getThumbnailLocation(link) {
	const youtubeInfo = getYoutubeInfo(link);

	if (youtubeInfo) {
		return youtubeInfo.thumbnailLocation;
	}

	return '';
}

import styled from 'styled-components/macro';

export const Container = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Wrapper = styled.div`
	width: 100%;
	max-width: 20em;
	padding: 1em;
`;

export const Title = styled.h1`
	text-align: center;
	margin-bottom: 1em;
`;

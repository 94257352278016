import { delay, put, call } from 'redux-saga/effects';

import { create } from './redux-units';

const GEOLOCATION_ERRORS = {
	PERMISSION_DENIED: 1,
	POSITION_UNAVAILABLE: 2,
	TIMEOUT: 3,
};

const locationOptions = {
	enableHighAccuracy: true,
	timeout: 5000,
	maximumAge: 0,
};

function getCurrentLocation() {
	return new Promise((resolve, reject) => {
		function onSuccess(position) {
			const location = {
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			};

			resolve(location);
		}

		function onError(err) {
			// user presses "no"
			if (err.code === GEOLOCATION_ERRORS.PERMISSION_DENIED) {
				reject(err);
				return;
			}

			// no location, but it could be there in the future
			resolve(null);
		}

		navigator.geolocation.getCurrentPosition(
			onSuccess,
			onError,
			locationOptions,
		);
	});
}

export default create('LOCATION', function*(success, error) {
	let running = true;

	while (running) {
		try {
			const location = yield call(getCurrentLocation);

			if (location) {
				yield put(success({ location }));
			}

			yield delay(11115000);
		} catch (e) {
			console.log(e);
			running = false;
		}
	}
});

import {
	USERS_LOGIN,
	USERS_LOGIN_SUCCESS,
	USERS_LOGIN_ERROR,
	USERS_LOGOUT,
	USERS_ME,
	USERS_ME_SUCCESS,
	USERS_ME_ERROR,
} from './../actions/users';

const initialState = {
	tokenIsLoading: false,
	tokenError: null,
	token: null,

	currentUserIsLoading: false,
	currentUserError: null,
	currentUser: null,
};

export default function usersReducer(state = initialState, action) {
	switch (action.type) {
		case USERS_LOGIN:
			return {
				...state,
				tokenIsLoading: true,
			};

		case USERS_LOGIN_SUCCESS:
			return {
				...state,
				token: action.payload.token,
				tokenError: null,
				tokenIsLoading: false,
			};

		case USERS_LOGIN_ERROR:
			return {
				...state,
				tokenError: action.payload.error,
				tokenIsLoading: false,
				token: null,
				currentUser: null,
			};

		case USERS_LOGOUT:
			return {
				...state,
				token: null,
				currentUser: null,
			};

		case USERS_ME:
			return {
				...state,
				currentUserIsLoading: true,
			};

		case USERS_ME_SUCCESS:
			return {
				...state,
				currentUserIsLoading: false,
				currentUserError: null,
				currentUser: action.payload.user,
			};

		case USERS_ME_ERROR:
			return {
				...state,
				currentUserIsLoading: false,
				currentUserError: action.payload.error,
				currentUser: null,
				token: null,
			};

		default:
			return state;
	}
}

// see DefaultDraftInlineStyle
// https://github.com/facebook/draft-js/blob/master/src/model/immutable/DefaultDraftInlineStyle.js

export default {
	BOLD: {
		fontWeight: '400',
	},

	CODE: {
		fontFamily: 'monospace',
		wordWrap: 'break-word',
	},

	ITALIC: {
		fontStyle: 'italic',
	},

	STRIKETHROUGH: {
		textDecoration: 'line-through',
	},

	UNDERLINE: {
		textDecoration: 'underline',
	},
};

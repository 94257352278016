import * as React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components/macro';
import { bindActionCreators } from 'redux';

import Dashboard from './../Dashboard/Dashboard';
import GlobalHotkey from './../GlobalHotkey/GlobalHotkey';
import LoadingOverlay from './../LoadingOverlay/LoadingOverlay';
import Login from './../Login/Login';

import * as clientSettingsActions from './../../store/actions/client-settings';

import * as themes from './../../theme';

import * as s from './AppStyle';

class App extends React.Component {
	onDarkModeHotkey = () => {
		this.props.clientSettingsActions.toggleDarkMode();
	};

	onMutedHotkey = () => {
		this.props.clientSettingsActions.toggleMuted();
	};

	renderLoading() {
		return <LoadingOverlay isVisible text="Entering the vault..." />;
	}

	renderSetupError() {
		return (
			<LoadingOverlay isVisible text={this.props.setupState.setupError} />
		);
	}

	renderDashboard() {
		return <Dashboard />;
	}

	renderContent() {
		if (this.props.setupState.setupIsLoading) {
			return this.renderLoading();
		}

		if (this.props.setupState.setupError) {
			return this.renderSetupError();
		}

		if (this.props.usersState.currentUser) {
			return this.renderDashboard();
		}

		return <Login />;
	}

	render() {
		return (
			<ThemeProvider
				theme={themes[this.props.clientSettingsState.themeName]}
			>
				<React.Fragment>
					<s.GlobalStyle />

					{this.renderContent()}

					<GlobalHotkey code="d" handler={this.onDarkModeHotkey} />
					<GlobalHotkey code="s" handler={this.onMutedHotkey} />
				</React.Fragment>
			</ThemeProvider>
		);
	}
}

export default connect(
	state => ({
		usersState: state.users,
		setupState: state.setup,
		clientSettingsState: state.clientSettings,
	}),
	dispatch => ({
		clientSettingsActions: bindActionCreators(
			clientSettingsActions,
			dispatch,
		),
	}),
)(App);

import { call, select, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { create } from './redux-units';
import * as remote from './../../remote';

import { notesSchema } from './../schemas/notes';

const locationSelector = state => state.location.location;

export default create('NOTES_SAVE', function*(success, error, listId, note) {
	try {
		const location = yield select(locationSelector);
		const response = yield call(remote.notesSave, note.body, location);
		const data = response.data;

		const normalizedData = normalize(data, notesSchema);

		yield put(
			success({
				...normalizedData,
				listId,
				note: data,
			}),
		);
	} catch (e) {
		yield put(error(note, e.message));
	}
});

import styled from 'styled-components/macro';

export const Container = styled.div`
	position: relative;
	cursor: pointer;
	flex: 1;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;

	// hide excess blurring
	overflow: hidden;
`;

export const ChildrenWrapper = styled.div`
	opacity: ${props => (props.isOpen ? 0 : 1)};
	filter: ${props => (props.isLoading ? 'blur(3px)' : 'none')};
	transition: filter 0.4s;
	width: 100%;
	height: 100%;

	> img,
	> video {
		max-width: 100%;
		max-height: 100%;
		display: block;
		margin: 0 auto;
	}

	> svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 30%;
		height: 30%;
		color: #fff;
	}
`;

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;

	opacity: ${props => (props.isLoading ? 0 : 1)};
	z-index: 13;
`;

export const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Image = styled.img`
	max-width: 100%;
	max-height: 100%;
`;

export const Video = styled.video`
	max-width: 100%;
	max-height: 100%;
`;

import * as React from 'react';
import * as blurhash from 'blurhash';

import * as s from './NoteStyle';

export default function Blur(props) {
	const canvasRef = React.useRef(null);

	React.useEffect(() => {
		if (!props.blurHash) {
			return;
		}

		// use small images and stretch them for improved speed
		const pixels = blurhash.decode(props.blurHash, 40, 30);
		const asClamped = new Uint8ClampedArray(pixels);
		const imageData = new ImageData(asClamped, 40, 30);
		const canvasEl = canvasRef.current;

		if (canvasEl) {
			const ctx = canvasEl.getContext('2d');
			ctx?.putImageData(imageData, 0, 0);
		}
	}, [props.blurHash]);

	if (!props.blurHash) {
		return null;
	}

	return <s.BlurBackgroundCanvas ref={canvasRef} width={40} height={30} />;
}

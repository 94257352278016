export function createListId() {
	return 'list-id-' + Date.now() + '-' + Math.random();
}

export const INITIAL_LIST_ID = 'INITIAL_LIST';

export function makeEmptyNote() {
	return makeNoteWithPlainText('');
}

export function makeNoteWithPlainText(body) {
	return {
		id: -1,
		body,
		is_todo: false,
		is_todo_done: false,
		archived_at: null,
		created_at: null,
		links: [],
		hashtags: [],
	};
}

export function isEmpty(note) {
	return !note || note.body === '' || note.body === '#' || note.body === '/';
}

export function startsWith(note, target) {
	return (
		!isEmpty(note) &&
		!isEmpty(target) &&
		target.body.toLowerCase().startsWith(note.body.toLowerCase())
	);
}

export function isNsfw(note) {
	if (!note) {
		return false;
	}

	return note.body.indexOf('#nsfw') !== -1;
}

export const USERS_LOGIN = 'USERS_LOGIN';
export const USERS_LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const USERS_LOGIN_ERROR = 'USERS_LOGIN_ERROR';

export function login(email, password) {
	return {
		type: USERS_LOGIN,
		payload: {
			email,
			password,
		},
	};
}

export function loginSuccess(token) {
	return {
		type: USERS_LOGIN_SUCCESS,
		payload: {
			token,
		},
	};
}

export function loginError(error) {
	return {
		type: USERS_LOGIN_ERROR,
		payload: {
			error,
		},
	};
}

export const USERS_LOGOUT = 'USERS_LOGOUT';

export function logout() {
	return {
		type: USERS_LOGOUT,
	};
}

export const USERS_ME = 'USERS_ME';
export const USERS_ME_SUCCESS = 'USERS_ME_SUCCESS';
export const USERS_ME_ERROR = 'USERS_ME_ERROR';

export function me() {
	return {
		type: USERS_ME,
	};
}

export function meSuccess(user) {
	return {
		type: USERS_ME_SUCCESS,
		payload: {
			user,
		},
	};
}

export function meError(error) {
	return {
		type: USERS_ME_ERROR,
		payload: {
			error,
		},
	};
}

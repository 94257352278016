import React, { useContext } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as notesActions from './../../../store/actions/notes';

import { makeNoteWithPlainText } from './../../../libs/note-utils';
import ListIdContext from './../../../contexts/ListIdContext';
import InsideEditorContext from './../inside-editor-context';

import { find as findRegex } from './regex';

import * as NoteStyle from './../../Note/NoteStyle';
import * as s from './../EditorStyle';

export const HASHTAG_REGEX = /([^\w/@]|^)((?:#|@|\/u\/)([\w\u0590-\u05ff-.]+))/g;

function hashtagStrategy(contentBlock, callback) {
	findRegex(HASHTAG_REGEX, contentBlock, match => {
		// sigh, if only we had positive lookbehind :)
		const start = match.index + match[1].length;
		const end = start + match[2].length;
		callback(start, end);
	});
}

export function extractHashtags(text) {
	const hashtags = [];
	let match;

	while (true) {
		match = HASHTAG_REGEX.exec(text);

		if (match === null) {
			break;
		}

		hashtags.push(match[1]);
	}

	return hashtags;
}

function HashtagSpan(props) {
	const insideEditor = useContext(InsideEditorContext);
	const listId = useContext(ListIdContext);

	function onClick(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		if (insideEditor) {
			return;
		}

		props.notesActions.setOrCreateList(
			listId,
			makeNoteWithPlainText(`${props.decoratedText} `),
			ev.button === 1,
		);
	}

	function onAppendClick(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		if (insideEditor) {
			return;
		}

		props.notesActions.append(listId, ` ${props.decoratedText}`);
	}

	return (
		<s.Hashtag
			href={`/search/${encodeURIComponent(props.decoratedText)}`}
			onClick={onClick}
			onAuxClick={onClick}
		>
			{props.children}

			{!insideEditor && (
				<NoteStyle.Action onClick={onAppendClick}>
					<FiPlusCircle />
				</NoteStyle.Action>
			)}
		</s.Hashtag>
	);
}

const hashtag = {
	strategy: hashtagStrategy,
	component: connect(
		null,
		dispatch => ({
			notesActions: bindActionCreators(notesActions, dispatch),
		}),
	)(HashtagSpan),
};

export default hashtag;

import * as React from 'react';

import {
	ContentState,
	Editor as DraftEditor,
	EditorState,
	KeyBindingUtil,
	RichUtils,
} from 'draft-js';
import 'draft-js/dist/Draft.css';

import decorators from './decorators';
// import fromMarkdown from './from-markdown';
import keybindings from './keybindings';
import styleMap from './style-map';
// import toMarkdown from './to-markdown';
import InsideEditorContext from './inside-editor-context';

import * as s from './EditorStyle';

const { hasCommandModifier } = KeyBindingUtil;

export default class Editor extends React.PureComponent {
	static defaultProps = {
		note: null,
		readOnly: false,
		onSave: () => {},
		onChange: () => {},
		onEscape: () => {},
		placeholder: 'Enter some text to create a new entry&hellip;',
		onEmpty: () => {},
	};

	constructor(props) {
		super(props);

		let editorState;

		if (props.note) {
			// const contentState = fromMarkdown(props.note.body);
			const contentState = ContentState.createFromText(props.note.body);
			editorState = EditorState.createWithContent(
				contentState,
				decorators,
			);
		} else {
			editorState = EditorState.createEmpty(decorators);
		}

		this.state = {
			editorState,
		};
	}

	componentDidMount() {
		if (!this.props.readOnly) {
			setTimeout(this.focus, 10);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!nextProps.readOnly && this.props.readOnly) {
			setTimeout(this.focus, 10);
		}
	}

	onChange = editorState => {
		this.setState(
			{
				editorState,
			},
			() => {
				const body = this.getBody();

				this.props.onChange && this.props.onChange(body);
			},
		);
	};

	onReturn = ev => {
		if (ev.shiftKey) {
			const newEditorState = RichUtils.insertSoftNewline(
				this.state.editorState,
			);

			this.onChange(newEditorState);

			return 'handled';
		} else if (hasCommandModifier(ev)) {
			this.triggerSave();

			setTimeout(this.focus, 10);

			return 'handled';
		}

		return 'not-handled';
	};

	handleKeyCommand = command => {
		if (command === 'escape') {
			this.blur();
			this.props.onEscape && this.props.onEscape();

			return 'handled';
		}

		return 'not-handled';
	};

	getBody(editorState) {
		const contentState = (
			editorState || this.state.editorState
		).getCurrentContent();

		return contentState.getPlainText().trim();
	}

	setBody(body) {
		const contentState = ContentState.createFromText(body);
		// const editorState = EditorState.createWithContent(contentState, decorators);
		const editorState = EditorState.push(
			this.state.editorState,
			contentState,
			'insert-characters',
		);

		this.setState({ editorState });
	}

	_editor = null;

	focus = () => {
		if (!this._editor) {
			return;
		}

		const selection = this.state.editorState.getSelection();
		const hasFocus = selection.getHasFocus();

		this._editor.focus();

		if (!hasFocus) {
			this.setState({
				editorState: EditorState.moveFocusToEnd(this.state.editorState),
			});
		}
	};

	blur() {
		if (!this._editor) {
			return;
		}

		this._editor.blur();
	}

	triggerSave() {
		this.props.onSave(this.getBody());

		if (this.props.emptyOnCreate) {
			const editorState = EditorState.createEmpty(decorators);
			this.onChange(editorState);
			this.props.onEmpty();
		}
	}

	render() {
		return (
			<InsideEditorContext.Provider value>
				<s.Container onClick={this.focus}>
					<DraftEditor
						editorState={this.state.editorState}
						onChange={this.onChange}
						placeholder={this.props.placeholder}
						ref={ref => {
							this._editor = ref;
						}}
						keyBindingFn={keybindings}
						handleKeyCommand={this.handleKeyCommand}
						customStyleMap={styleMap}
						stripPastedStyles
						spellCheck
						handleReturn={this.onReturn}
						readOnly={this.props.readOnly}
					/>
				</s.Container>
			</InsideEditorContext.Provider>
		);
	}
}

import * as React from 'react';

import * as s from './NoteStyle';

export default class ErrorNote extends React.PureComponent {
	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			error,
		};
	}

	state = {
		hasError: false,
		error: null,
	};

	componentDidCatch(error) {
		this.setState({
			hasError: true,
			error,
		});
	}

	renderError() {
		return <s.Container>Oops: {this.props.note.body}</s.Container>;
	}

	render() {
		if (this.state.hasError) {
			return this.renderError();
		}

		return this.props.children;
	}
}

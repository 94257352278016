import { applyMiddleware, compose, createStore } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import rootReducer from './reducers';
import rootSaga from './sagas';
import { middleware as scrollMiddleware } from './listeners/scroll';
import { middleware as focusMiddleware } from './listeners/focus';

import { setup } from './actions/setup';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk, scrollMiddleware, focusMiddleware];

if (process.env.NODE_ENV !== 'production') {
	middlewares.push(createLogger({ collapsed: true }));
}

const persistConfig = {
	key: 'mindvault',
	storage,
	whitelist: ['clientSettings', 'users', 'music'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
	persistedReducer,
	undefined,
	compose(applyMiddleware(...middlewares)),
);

export const persistor = persistStore(store, null, () => {
	store.dispatch(setup());
});

sagaMiddleware.run(rootSaga);

export default store;

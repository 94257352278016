// offset can be used as a poor mans positive lookbehind
export function find(regex, contentBlock, callback) {
	const blockType = contentBlock.getType();
	const text = contentBlock.getText();

	if (blockType === 'code-block') {
		return;
	}

	let match;

	while (true) {
		match = regex.exec(text);

		if (match === null) {
			break;
		}

		callback(match);
	}
}

export default function findWithRegex(regex, contentBlock, callback) {
	find(regex, contentBlock, match => {
		const start = match.index;
		callback(start, start + match[0].length);
	});
}

import { makeEmptyNote, createListId } from './../../libs/note-utils';

export const NOTES_LIST = 'NOTES_LIST';
export const NOTES_LIST_SUCCESS = 'NOTES_LIST_SUCCESS';
export const NOTES_LIST_ERROR = 'NOTES_LIST_ERROR';

export function list(listId, searchNote = makeEmptyNote()) {
	return {
		type: NOTES_LIST,
		payload: {
			listId,
			searchNote,
		},
	};
}

export function listSuccess(
	listId,
	searchNote,
	notes,
	total,
	hasMore,
	nextCursor,
	topics,
) {
	return {
		type: NOTES_LIST_SUCCESS,
		payload: {
			listId,
			searchNote,
			notes,
			total,
			hasMore,
			nextCursor,
			topics,
		},
	};
}

export function listError(listId, error) {
	return {
		type: NOTES_LIST_ERROR,
		payload: {
			listId,
			error,
		},
	};
}

export const NOTES_SEARCH = 'NOTES_SEARCH';

export function search(listId, searchNote) {
	return {
		type: NOTES_SEARCH,
		payload: {
			listId,
			searchNote,
		},
	};
}

export const NOTES_RESET = 'NOTES_RESET';

export function reset(listId, searchNote) {
	return {
		type: NOTES_RESET,
		payload: {
			listId,
			searchNote,
		},
	};
}

export const NOTES_CLEAR = 'NOTES_CLEAR';

export function clear(listId) {
	return {
		type: NOTES_CLEAR,
		payload: {
			listId,
		},
	};
}

export const NOTES_LOAD_MORE = 'NOTES_LOAD_MORE';
export const NOTES_LOAD_MORE_SUCCESS = 'NOTES_LOAD_MORE_SUCCESS';
export const NOTES_LOAD_MORE_ERROR = 'NOTES_LOAD_MORE_ERROR';

export function loadMore(listId) {
	return {
		type: NOTES_LOAD_MORE,
		payload: {
			listId,
		},
	};
}

export function loadMoreSuccess(listId, notes, total, hasMore, nextCursor) {
	return {
		type: NOTES_LOAD_MORE_SUCCESS,
		payload: {
			listId,
			notes,
			total,
			hasMore,
			nextCursor,
		},
	};
}

export function loadMoreError(listId, error) {
	return {
		type: NOTES_LOAD_MORE_ERROR,
		payload: {
			listId,
			error,
		},
	};
}

export const NOTES_REFRESH = 'NOTES_REFRESH';

export function refresh(listId) {
	return {
		type: NOTES_REFRESH,
		payload: {
			listId,
		},
	};
}

export const NOTES_NEW_LIST = 'NOTES_NEW_LIST';

export function newList(initialSearchNote) {
	return {
		type: NOTES_NEW_LIST,
		payload: {
			listId: createListId(),
			initialSearchNote,
		},
	};
}

export const NOTES_SET_OR_CREATE_LIST = 'NOTES_SET_OR_CREATE_LIST';

export function setOrCreateList(listId, searchNote, forceCreate = false) {
	return {
		type: NOTES_SET_OR_CREATE_LIST,
		payload: {
			listId,
			searchNote,
			forceCreate,
		},
	};
}

export const NOTES_APPEND = 'NOTES_APPEND';

export function append(listId, text) {
	return {
		type: NOTES_APPEND,
		payload: {
			listId,
			text,
		},
	};
}

export const NOTES_CLOSE_LIST = 'NOTES_CLOSE_LIST';

export function closeList(listId) {
	return {
		type: NOTES_CLOSE_LIST,
		payload: {
			listId,
		},
	};
}

import produce from 'immer';

import notesArchive from './../units/notes-archive';
import notesDelete from './../units/notes-delete';
// import notesSave from './../units/notes-save';

import { makeEmptyNote, INITIAL_LIST_ID } from './../../libs/note-utils';

import { USERS_LOGOUT } from './../actions/users';

import {
	NOTES_LIST,
	NOTES_LIST_SUCCESS,
	NOTES_LIST_ERROR,
	NOTES_SEARCH,
	NOTES_RESET,
	NOTES_CLEAR,
	NOTES_LOAD_MORE,
	NOTES_LOAD_MORE_SUCCESS,
	NOTES_LOAD_MORE_ERROR,
	NOTES_NEW_LIST,
	NOTES_CLOSE_LIST,
} from './../actions/notes';

const initialListState = {
	isLoading: false,
	isLoadingMore: false,
	isClearing: false,
	isResetting: false,
	error: null,
	searchNote: makeEmptyNote(),
	notes: [],
	nextCursor: null,
	total: 0,
	hasMore: false,
	topics: [],
};

const initialState = {};
initialState[INITIAL_LIST_ID] = initialListState;
// initialState['second'] = initialListState;

function produceListState(state, producer) {
	return produce(state, draft => {
		const listIds = Object.keys(draft);

		listIds.forEach(listId => {
			const listState = draft[listId];

			producer(listState, listId);
		});
	});
}

function insertNote(state, listId, note) {
	return produceListState(state, (listState, itemListId) => {
		if (!listState.searchNote.body || listId === itemListId) {
			listState.notes.unshift(note.id);
		}
	});
}

function removeFromListState(listState, note) {
	const index = listState.notes.indexOf(note.id);

	if (index === -1) {
		return;
	}

	listState.notes.splice(index, 1);
}

function deleteNote(state, note) {
	return produceListState(state, listState => {
		removeFromListState(listState, note);
	});
}

// searches include archived notes, "no search" lists do not
function archiveNote(state, note) {
	return produceListState(state, listState => {
		if (!listState.searchNote.body) {
			removeFromListState(listState, note);
		}
	});
}

export default function notesReducer(state = initialState, action) {
	// if (action.payload && action.payload.listId && !state[action.payload.listId]) {
	// 	state[action.payload.listId] = initialListState;
	// }

	switch (action.type) {
		case NOTES_NEW_LIST:
			return {
				...state,
				[action.payload.listId]: {
					...initialListState,
					searchNote: action.payload.initialSearchNote,
				},
			};

		case NOTES_CLOSE_LIST:
			return produce(state, draft => {
				delete draft[action.payload.listId];
			});

		case NOTES_LIST:
			return {
				...state,
				[action.payload.listId]: {
					...state[action.payload.listId],
					isLoading: true,
					searchNote: action.payload.searchNote,
					isClearing: false,
					isResetting: false,
				},
			};

		case NOTES_LIST_SUCCESS:
			return {
				...state,
				[action.payload.listId]: {
					...state[action.payload.listId],
					nextCursor: action.payload.nextCursor,
					isLoading: false,
					error: null,
					...action.payload,
				},
			};

		case NOTES_LIST_ERROR:
			return {
				...state,
				[action.payload.listId]: {
					...state[action.payload.listId],
					isLoading: false,
					error: action.payload.error,
				},
			};

		case NOTES_SEARCH:
			return {
				...state,
				[action.payload.listId]: {
					...state[action.payload.listId],
					isClearing: false,
					isResetting: false,
				},
			};

		case NOTES_RESET:
			return {
				...state,
				[action.payload.listId]: {
					...state[action.payload.listId],
					searchNote: action.payload.searchNote,
					isResetting: true,
				},
			};

		case NOTES_CLEAR:
			return {
				...state,
				[action.payload.listId]: {
					...state[action.payload.listId],
					isClearing: true,
				},
			};

		case NOTES_LOAD_MORE:
			return {
				...state,
				[action.payload.listId]: {
					...state[action.payload.listId],
					isLoadingMore: true,
				},
			};

		case NOTES_LOAD_MORE_SUCCESS:
			return {
				...state,
				[action.payload.listId]: {
					...state[action.payload.listId],
					isLoading: false,
					isLoadingMore: false,
					error: null,
					...action.payload,
					notes: [
						...state[action.payload.listId].notes,
						...action.payload.notes,
					],
				},
			};

		case NOTES_LOAD_MORE_ERROR:
			return {
				...state,
				[action.payload.listId]: {
					...state[action.payload.listId],
					isLoading: false,
					isLoadingMore: false,
					error: action.payload.error,
				},
			};

		// case notesSave.SUCCESS:
		// 	return insertNote(
		// 		state,
		// 		action.payload.listId,
		// 		action.payload.note,
		// 	);

		case notesDelete.SUCCESS:
			return deleteNote(state, action.payload.note);

		case notesArchive.SUCCESS:
			return archiveNote(state, action.payload.note);

		case USERS_LOGOUT:
			return initialState;

		default:
			return state;
	}
}

import {
	all,
	delay,
	call,
	put,
	select,
	fork,
	take,
	cancel,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as remote from './../../remote';

import {
	NOTES_LIST,
	list as notesList,
	listSuccess,
	listError,
	NOTES_SEARCH,
	NOTES_RESET,
	NOTES_CLEAR,
	NOTES_LOAD_MORE,
	loadMoreSuccess,
	loadMoreError,
	NOTES_REFRESH,
	NOTES_SET_OR_CREATE_LIST,
	NOTES_APPEND,
	newList as notesNewList,
	reset as notesReset,
} from './../actions/notes';
import * as focusActions from './../../store/actions/focus';

import { toTop } from './../actions/scroll';

import { setEntities } from './../actions/by-id';

import { notesSchemaList } from './../schemas/notes';

const selectedListIdSelector = state => state.selectedList;
const searchNoteSelector = (state, listId) => state.notes[listId].searchNote;
// const nextCursorSelector = (state, listId) => state.notes[listId].nextCursor;

// not yet normalized
const noteListIdSelector = (state, listId) => state.notes[listId].notes;

function preprocessBody(note) {
	return String(note.body)
		.trim()
		.replace(/\n+/, ' ')
		.replace(/^(#|\/)$/, '');
}

// import { isEmpty, startsWith } from './../../libs/note-utils';

function* list({ payload: { listId, searchNote } }) {
	try {
		const q = preprocessBody(searchNote);
		const response = yield call(remote.notesList, q);
		const data = response.data;

		const normalizedData = normalize(data.thoughts, notesSchemaList);

		yield put(setEntities(normalizedData));

		yield put(
			listSuccess(
				listId,
				searchNote,
				normalizedData.result,
				data.total,
				data.has_more,
				data.next_cursor,
				data.topics,
			),
		);

		yield delay(10);

		yield put(toTop(listId));
	} catch (e) {
		yield put(listError(listId, e.message));
	}
}

function* search({ payload: { listId, searchNote } }) {
	yield delay(1250);
	yield put(notesList(listId, searchNote));
}

function* reset({ payload: { listId, searchNote } }) {
	// give system time to act on the resetting action
	yield delay(1);
	yield put(notesList(listId, searchNote));
}

function* clear({ payload: { listId } }) {
	// give system time to act on the clearing action
	yield delay(1);
	yield put(notesList(listId));
}

function* loadMore({ payload: { listId } }) {
	try {
		const searchNote = yield select(searchNoteSelector, listId);
		const q = preprocessBody(searchNote);
		const noteIds = yield select(noteListIdSelector, listId);
		const nextCursor = noteIds.join(',');

		const response = yield call(remote.notesList, q, nextCursor);
		const data = response.data;

		const normalizedData = normalize(data.thoughts, notesSchemaList);

		yield put(setEntities(normalizedData));

		yield put(
			loadMoreSuccess(
				listId,
				normalizedData.result,
				data.total,
				data.has_more,
				data.next_cursor,
			),
		);
	} catch (e) {
		yield loadMoreError(listId, e.message);
	}
}

function* refresh({ payload: { listId } }) {
	const searchNote = yield select(searchNoteSelector, listId);
	yield put(notesList(listId, searchNote));
}

function* setOrCreateList({ payload: { listId, searchNote, forceCreate } }) {
	if (forceCreate) {
		yield put(notesNewList(searchNote));
		const selectedListId = yield select(selectedListIdSelector);
		yield delay(5);
		yield put(focusActions.editor(selectedListId));
	} else {
		yield put(notesReset(listId, searchNote));
		yield delay(5);
		yield put(focusActions.editor(listId));
	}
}

function* append({ payload: { listId, text } }) {
	const searchNote = yield select(searchNoteSelector, listId);

	searchNote.body =
		(searchNote.body.trimEnd() + ' ' + text.trimStart()).trim() + ' ';

	yield put(notesReset(listId, searchNote));
	yield delay(5);
	yield put(focusActions.editor(listId));
}

function takeLatestWithId(patternOrChannel, idSelector, saga) {
	return fork(function*() {
		const lastTasks = {};

		while (true) {
			const action = yield take(patternOrChannel);
			const id = idSelector(action);

			if (lastTasks[id]) {
				yield cancel(lastTasks[id]);
			}

			lastTasks[id] = yield fork(saga, action);
		}
	});
}

const listIdSelector = action => action.payload.listId;

export default function* notesSaga() {
	yield all([
		takeLatestWithId(NOTES_LIST, listIdSelector, list),
		takeLatestWithId(NOTES_SEARCH, listIdSelector, search),
		takeLatestWithId(NOTES_RESET, listIdSelector, reset),
		takeLatestWithId(NOTES_CLEAR, listIdSelector, clear),
		takeLatestWithId(NOTES_LOAD_MORE, listIdSelector, loadMore),
		takeLatestWithId(NOTES_REFRESH, listIdSelector, refresh),
		takeLatestWithId(
			NOTES_SET_OR_CREATE_LIST,
			listIdSelector,
			setOrCreateList,
		),
		takeLatestWithId(NOTES_APPEND, listIdSelector, append),
	]);
}

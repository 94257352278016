// https://github.com/facebook/draft-js/blob/master/src/component/utils/getDefaultKeyBinding.js

import findKey from 'lodash/findKey';

import { getDefaultKeyBinding, KeyBindingUtil } from 'draft-js';

const { hasCommandModifier } = KeyBindingUtil;

const bindings = {
	// 'editor-save':
	// 	ev => ev.keyCode === 83 #<{(| s |)}># && hasCommandModifier(ev),
	'unordered-list-item': ev =>
		ev.keyCode === 56 /* 8 */ && ev.shiftKey && hasCommandModifier(ev),
	escape: ev => ev.keyCode === 27 /* ESC */,
	// 'hashtag-1': ev => ev.codeev.code === 'h'
	nextNote: ev => ev.ctrlKey && ev.code === 'KeyJ',
	previousNote: ev => ev.ctrlKey && ev.code === 'KeyK',
};

export default function getBindingFn(ev) {
	const command = findKey(bindings, (check, name) => {
		if (check(ev)) {
			return name;
		}

		return null;
	});

	return command || getDefaultKeyBinding(ev);
}

import * as React from 'react';
import each from 'lodash/each';
import posed, { PoseGroup } from 'react-pose';
import reduce from 'lodash/reduce';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { tween } from 'popmotion';

import { isNsfw } from './../../libs/note-utils';

import * as navigationActions from './../../store/actions/navigation';
import Medium from './../Medium/Medium';
import { notesListSelector } from './../../store/selectors/notes-list';

import * as s from './GalleryStyle';

const AnimatedItem = posed.li({
	flip: {
		transition: tween,
	},
	enter: {
		opacity: 1,
	},
	exit: {
		opacity: 0,
	},
});

const IMAGE_TYPES = ['IMAGE', 'VIDEO'];

function isImageType(link) {
	return IMAGE_TYPES.indexOf(link.kind) !== -1;
}

function GalleryListItem({ link, nsfw, muted }) {
	return (
		<s.Item>
			<Medium link={link} nsfw={nsfw} muted={muted} contain />
		</s.Item>
	);
}

function GalleryList_({
	listId,
	links,
	navigationActions: { set },
	clientSettingsState: { muted },
}) {
	function onMouse(link, ev) {
		if (!ev.ctrlKey) {
			return;
		}

		set(listId, link.noteId);
	}

	return (
		<s.ListWrapper>
			<s.Wrapper>
				<PoseGroup animateOnMount>
					{links.map(link => (
						<AnimatedItem
							key={link.id}
							onMouseEnter={onMouse.bind(null, link)}
							onMouseMove={onMouse.bind(null, link)}
						>
							<GalleryListItem
								link={link}
								nsfw={link.nsfw}
								muted={muted}
							/>
						</AnimatedItem>
					))}
				</PoseGroup>
			</s.Wrapper>
		</s.ListWrapper>
	);
}

const GalleryList = connect(
	state => ({
		clientSettingsState: state.clientSettings,
	}),
	dispatch => ({
		navigationActions: bindActionCreators(navigationActions, dispatch),
	}),
)(GalleryList_);

function NoteListGallery_(props) {
	const notes = props.notesList;

	if (!notes || notes.length === 0) {
		return null;
	}

	const linkIds = {};
	let countImages = 0;
	const allImages = reduce(
		notes,
		(list, note) => {
			let isImage = false;

			each(note.links, link => {
				if (isImageType(link) && !linkIds[link.id]) {
					isImage = true;
					list.push({ ...link, noteId: note.id, nsfw: isNsfw(note) });
					linkIds[link.id] = true;
				}
			});

			if (isImage) {
				countImages += 1;
			}

			return list;
		},
		[],
	);

	if (allImages.length <= 10 && allImages.length < countImages) {
		return null;
	}

	if (allImages.length === 0) {
		return null;
	}

	return <GalleryList listId={props.listId} links={allImages} />;
}

const NoteListGallery = connect((state, props) => ({
	...notesListSelector(props.listId, state),
}))(NoteListGallery_);

function Gallery(props) {
	return (
		<s.Container>
			{Object.keys(props.notesState).map(listId => (
				<NoteListGallery listId={listId} key={listId} />
			))}
		</s.Container>
	);
}

export default connect(state => ({
	notesState: state.notes,
}))(Gallery);

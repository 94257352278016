import { combineReducers } from 'redux';

import byId from './by-id';
import setup from './setup';
import users from './users';
import music from './music';
import notes from './notes';
import navigation from './navigation';
import clientSettings from './client-settings';

import notesDelete from './../units/notes-delete';
import notesEdit from './../units/notes-edit';
import notesSave from './../units/notes-save';
import notesArchive from './../units/notes-archive';
import notesUnarchive from './../units/notes-unarchive';
import notesPin from './../units/notes-pin';
import notesUnpin from './../units/notes-unpin';
import notesSetReminder from './../units/notes-set-reminder';
import notesClearReminder from './../units/notes-clear-reminder';

import location from './../units/location';

export default combineReducers({
	byId,
	setup,
	users,
	music,
	notes,
	navigation,
	clientSettings,

	notesEdit: notesEdit.reducer,
	notesDelete: notesDelete.reducer,
	notesSave: notesSave.reducer,
	notesArchive: notesArchive.reducer,
	notesUnarchive: notesUnarchive.reducer,
	notesPin: notesPin.reducer,
	notesUnpin: notesUnpin.reducer,
	notesSetReminder: notesSetReminder.reducer,
	notesClearReminder: notesClearReminder.reducer,

	location: location.reducer,
});

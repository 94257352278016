export const CLIENT_SETTINGS_SET_THEME = 'CLIENT_SETTINGS_SET_THEME';
export const CLIENT_SETINGS_SET_MUTED = 'CLIENT_SETINGS_SET_MUTED';

export function setTheme(themeName) {
	return {
		type: CLIENT_SETTINGS_SET_THEME,
		payload: {
			themeName,
		},
	};
}

export function toggleDarkMode() {
	return (dispatch, getStore) => {
		const store = getStore();
		const currentThemeName = store.clientSettings.themeName;
		const themeName = currentThemeName === 'light' ? 'dark' : 'light';

		dispatch(setTheme(themeName));
	};
}

export function setMuted(muted) {
	return {
		type: CLIENT_SETINGS_SET_MUTED,
		payload: {
			muted,
		},
	};
}

export function toggleMuted() {
	return (dispatch, getStore) => {
		const store = getStore();
		const currentMuted = store.clientSettings.muted;

		dispatch(setMuted(!currentMuted));
	};
}

const dimensions = {
	headerHeight: '4em',
	width: '41em',
};

export const light = {
	// backgroundColor: '#fefefe',
	backgroundColor: 'papayawhip',
	textColor: '#333',
	errorColor: 'red',
	borderColor: '#eee',
	accentColor: 'teal',

	...dimensions,
};

export const dark = {
	// backgroundColor: '#201f1f',
	backgroundColor: '#000',
	textColor: '#bbb',
	errorColor: 'red',
	borderColor: '#302f2f',
	accentColor: 'teal',
	// accentColor: '#5f021f',

	...dimensions,
};

export const defaultThemeName = 'dark';

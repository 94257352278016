import { call, delay, put } from 'redux-saga/effects';

import { createSingle } from './redux-units';
import * as remote from './../../remote';
import { next } from './../actions/navigation';

export default createSingle('NOTES_DELETE', function*(success, error, note) {
	try {
		yield delay(5);
		yield put(next());

		yield call(remote.notesDelete, note.id);
		yield put(success({ note }));
	} catch (e) {
		yield put(error(e.message));
	}
});

import { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`
	html {
		background-color: ${props => props.theme.backgroundColor};
		color: ${props => props.theme.textColor};

		transition: background-color 0.4s, color 0.4s;
	}
`;

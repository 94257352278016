import styled from 'styled-components/macro';

export const Container = styled.form``;

export const InputWrapper = styled.div`
	position: relative;
	margin-bottom: 1em;
	padding-bottom: 1.5em;
`;

export const Label = styled.label`
	display: block;
	cursor: pointer;
`;

export const Input = styled.input`
	font-size: 1em;

	&:not([type='checkbox']) {
		display: block;
		width: 100%;
		padding: 0.3em 0;
		border: none;
		border-bottom: 1px solid ${props => props.theme.borderColor};
		background-color: inherit;
		color: inherit;
		margin-top: 0.2em;
	}

	&[type='checkbox'] {
		display: inline-block;
		margin-right: 0.5em;
	}
`;

export const ErrorContainer = styled.div`
	padding: 1em;
	border: 1px solid ${props => props.theme.errorColor};
	margin-bottom: 1em;
	background: #de908e; // some lighter error color
`;

export const ErrorTitle = styled.h2`
	color: ${props => props.theme.errorColor};
`;

export const Errors = styled.ul`
	margin-bottom: 0;
	padding-left: 1.5em;
`;

export const ErrorMessage = styled.li`
	color: ${props => props.theme.errorColor};
`;

export const HelpText = styled.p`
	font-size: smaller;
	margin-bottom: 0.1em;
`;

export const ErrorHint = styled(HelpText)`
	color: ${props => props.theme.errorColor};
	position: absolute;
	bottom: 0;
	left: 0;
`;

export const ButtonWrapper = styled.div`
	text-align: right;
`;

export const Submit = styled.button`
	apperance: none;
	border: 1px solid #ccc;
	padding: 0.3em 0.7em;
	background: none;
	border-radius: 0;
	color: inherit;
	cursor: pointer;
	font-size: 1em;
`;

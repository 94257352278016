import * as React from 'react';

import mousetrap from 'mousetrap';

export default class GlobalHotkey extends React.PureComponent {
	static defaultProps = {
		active: true,
	};

	componentDidMount() {
		if (this.props.active) {
			this.attach();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.active !== this.props.active) {
			if (this.props.active) {
				this.attach();
			} else {
				this.detach();
			}
		}
	}

	componentWillUnmount() {
		if (this.props.active) {
			this.detach();
		}
	}

	attach() {
		mousetrap.bind(this.props.code, this.handler);
	}

	detach() {
		mousetrap.unbind(this.props.code);
	}

	handler = (ev, combo) => {
		this.props.handler(combo, ev);

		return false;
	};

	render() {
		return null;
	}
}

import * as React from 'react';
import ReactDOM from 'react-dom';
import Animated from 'animated';

import GlobalHotkey from './../GlobalHotkey/GlobalHotkey';

import * as s from './LightboxStyle';

const AnimatedOverlay = Animated.createAnimatedComponent(s.Overlay);
const AnimatedWrapper = Animated.createAnimatedComponent(s.Wrapper);
const AnimatedImage = Animated.createAnimatedComponent(s.Image);
const AnimatedVideo = Animated.createAnimatedComponent(s.Video);

const lightboxRoot = document.body;

export default class Lightbox extends React.Component {
	isOpenScheduled = false;
	childrenWrapper = React.createRef();
	el = null;

	constructor(props) {
		super(props);

		this.el = document.createElement('div');
	}

	state = {
		isOpen: false,
		isLoading: false,
		isVisible: false,
		originValue: {
			left: new Animated.Value(0),
			top: new Animated.Value(0),
			width: new Animated.Value(0),
			height: new Animated.Value(0),
			opacity: new Animated.Value(0),
		},
	};

	onContainerClick = () => {
		if (this.state.isOpen) {
			this.close();
		} else {
			this.open();
		}
	};

	onImageLoad = () => {
		if (this.isOpenScheduled) {
			this.isOpenScheduled = false;

			this.setState({
				isLoading: false,
			});

			this.matchWindowDimensions();
		}
	};

	getAnimation(value, toValue) {
		return Animated.timing(value, {
			duration: 210,
			toValue,
		});
	}

	scheduleMatchWindowDimensions() {
		this.isOpenScheduled = true;
	}

	matchWindowDimensions() {
		Animated.parallel([
			this.getAnimation(this.state.originValue.left, 0),
			this.getAnimation(this.state.originValue.top, 0),
			this.getAnimation(this.state.originValue.width, window.innerWidth),
			this.getAnimation(
				this.state.originValue.height,
				window.innerHeight,
			),
			this.getAnimation(this.state.originValue.opacity, 1),
		]).start();
	}

	open() {
		// setup portal element
		lightboxRoot.appendChild(this.el);

		if (this.state.isOpen || !this.childrenWrapper.current) {
			return;
		}

		this.setState({
			isLoading: true,
		});

		if (!this.childrenWrapper.current) {
			return;
		}

		const rect = this.childrenWrapper.current.getBoundingClientRect();

		this.state.originValue.left.setValue(rect.x);
		this.state.originValue.top.setValue(rect.y);
		this.state.originValue.width.setValue(rect.width);
		this.state.originValue.height.setValue(rect.height);
		this.state.originValue.opacity.setValue(0);

		this.setState({
			isOpen: true,
		});

		this.scheduleMatchWindowDimensions();
	}

	close = () => {
		if (!this.state.isOpen || !this.childrenWrapper.current) {
			return;
		}

		const rect = this.childrenWrapper.current.getBoundingClientRect();

		Animated.parallel([
			this.getAnimation(this.state.originValue.left, rect.x),
			this.getAnimation(this.state.originValue.top, rect.y),
			this.getAnimation(this.state.originValue.width, rect.width),
			this.getAnimation(this.state.originValue.height, rect.height),
			this.getAnimation(this.state.originValue.opacity, 0),
		]).start(() => {
			this.setState(
				{
					isOpen: false,
				},
				() => {
					// remove portal element
					lightboxRoot.removeChild(this.el);
				},
			);
		});
	};

	toggle() {
		if (this.state.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	renderChildren() {
		return (
			<s.ChildrenWrapper
				ref={this.childrenWrapper}
				isLoading={this.state.isLoading}
				isOpen={this.state.isOpen && !this.state.isLoading}
			>
				{this.props.children}
			</s.ChildrenWrapper>
		);
	}

	renderContent() {
		const boxShadowValue = this.state.originValue.opacity.interpolate({
			inputRange: [0, 1],
			outputRange: [0, 1],
		});

		if (this.props.imageSrc) {
			return (
				<AnimatedImage
					src={this.props.imageSrc}
					alt=""
					onLoad={this.onImageLoad}
					style={{
						boxShadow: Animated.template`0px 0px ${boxShadowValue}em rgba(0, 0, 0, 0.9)`,
					}}
				/>
			);
		}

		if (this.props.videoSrc) {
			return (
				<AnimatedVideo
					controls
					muted={this.props.muted}
					loop
					autoPlay
					playsInline
					src={this.props.videoSrc}
					poster={this.props.posterSrc}
					onLoadStart={this.onImageLoad}
					style={{
						boxShadow: Animated.template`0px 0px ${boxShadowValue}em rgba(0, 0, 0, 0.9)`,
					}}
				/>
			);
		}

		return null;
	}

	renderImage() {
		if (!this.state.isOpen) {
			return null;
		}

		return ReactDOM.createPortal(
			<AnimatedOverlay
				isLoading={this.state.isLoading}
				style={{
					backgroundColor: this.state.originValue.opacity.interpolate(
						{
							inputRange: [0, 1],
							outputRange: [
								'rgba(0, 0, 0, 0)',
								'rgba(0, 0, 0, 0.8)',
							],
						},
					),
				}}
			>
				<AnimatedWrapper
					style={{
						left: this.state.originValue.left,
						top: this.state.originValue.top,
						width: this.state.originValue.width,
						height: this.state.originValue.height,
						padding: this.state.originValue.opacity.interpolate({
							inputRange: [0, 1],
							outputRange: ['0em', '1em'],
						}),
					}}
				>
					{this.renderContent()}
				</AnimatedWrapper>
			</AnimatedOverlay>,
			this.el,
		);
	}

	renderLoader() {
		return null;
		// return (
		//	 <LoadingOverlay
		//		 isVisible={this.state.isLoading}
		//		 transparent
		//		 cover
		//	 />
		// );
	}

	renderHotkeys() {
		return (
			<React.Fragment>
				<GlobalHotkey
					code="esc"
					handler={this.close}
					active={this.state.isOpen}
				/>
			</React.Fragment>
		);
	}

	render() {
		return (
			<s.Container onClick={this.onContainerClick}>
				{this.renderHotkeys()}

				{this.renderChildren()}
				{this.renderImage()}
				{this.renderLoader()}
			</s.Container>
		);
	}
}

import * as React from 'react';

import { find as findRegex } from './regex';

import * as s from './../EditorStyle';

// eslint-disable-next-line max-len
const URL_PATTERN = /((((https?|ftp):)?\/\/)|^|(\s))((?!(10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(\.\d{1,3}){2})(?!172\.(1[6-9]|2\d|3[0-1])(\.\d{1,3}){2})([1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(1?\d{1,2}|2[0-4]\d|25[0-5])){2}(\.([1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(([a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(\.([a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(\.([a-z\u00a1-\uffff]{2,})))(:\d{2,5})?([/?#]\S*)?/gi;

function automaticLinkStrategy(contentBlock, callback) {
	findRegex(URL_PATTERN, contentBlock, match => {
		// sigh, if only we had positive lookbehind :)
		const spaceLength = (match[5] && match[5].length) || 0;
		const start = match.index + spaceLength;
		const end = start + (match[0].length - spaceLength);
		callback(start, end);
	});
}

function AutomaticLink(props) {
	function onClick(ev) {
		// we want to open the link, but we don't want to toggle the note
		ev.stopPropagation();
	}

	const url = props.decoratedText;

	return (
		<s.Link
			href={url}
			onClick={onClick}
			target="_blank"
			rel="noopener noreferrer"
		>
			{props.children}
		</s.Link>
	);
}

export default {
	strategy: automaticLinkStrategy,
	component: AutomaticLink,
};

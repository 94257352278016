import * as React from 'react';

export class Input extends React.PureComponent {
	static defaultProps = {
		onChange: () => {},
		innerRef: () => {},
	};

	render() {
		return null;
	}
}

export const NAVIGATION_NEXT = 'NAVIGATION_NEXT';
export const NAVIGATION_PREVIOUS = 'NAVIGATION_PREVIOUS';
export const NAVIGATION_NEXT_LIST = 'NAVIGATION_NEXT_LIST';
export const NAVIGATION_PREVIOUS_LIST = 'NAVIGATION_PREVIOUS_LIST';
export const NAVIGATION_FIRST = 'NAVIGATION_FIRST';
export const NAVIGATION_LAST = 'NAVIGATION_LAST';
export const NAVIGATION_SET = 'NAVIGATION_SET';
export const NAVIGATION_SET_LIST = 'NAVIGATION_SET_LIST';
export const NAVIGATION_CLOSE_LIST = 'NAVIGATION_CLOSE_LIST';

export function next() {
	return {
		type: NAVIGATION_NEXT,
	};
}

export function previous() {
	return {
		type: NAVIGATION_PREVIOUS,
	};
}

export function nextList() {
	return {
		type: NAVIGATION_NEXT_LIST,
	};
}

export function previousList() {
	return {
		type: NAVIGATION_PREVIOUS_LIST,
	};
}

export function first() {
	return {
		type: NAVIGATION_FIRST,
	};
}

export function last() {
	return {
		type: NAVIGATION_LAST,
	};
}

export function set(listId, selected) {
	return {
		type: NAVIGATION_SET,
		payload: {
			listId,
			selected,
		},
	};
}

export function setList(listId) {
	return {
		type: NAVIGATION_SET_LIST,
		payload: {
			listId,
		},
	};
}

export function closeList(listId) {
	return {
		type: NAVIGATION_CLOSE_LIST,
		payload: {
			listId,
		},
	};
}

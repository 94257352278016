import styled from 'styled-components/macro';
import { mix } from 'polished';

export const Button = styled.button`
	apperance: none;
	border: none;
	background: none;
	border-radius: 0;
	cursor: pointer;
	font-size: 1em;
	padding: 0 0.1em;
	color: ${props =>
		mix(0.4, props.theme.textColor, props.theme.backgroundColor)};

	&:hover {
		// very important, because, fuck it
		color: ${props => props.theme.textColor} !important;
	}
`;

export const Container = styled.section`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: ${props => props.theme.backgroundColor};
	transition: background-color 0.4s;
	z-index: 10;
`;

export const Wrapper = styled.div`
	margin: 0 auto;
	width: 100%;
	height: 100%;
	max-width: ${props => props.theme.width};
	padding: 0 1em;
	position: relative;
	display: flex;
	flex-direction: column;

	&::before {
		content: '';
		display: block;
		left: 1em;
		right: 1em;
		height: 1px;
		position: absolute;
		top: -1px;
		background-color: ${props => props.theme.borderColor};
	}
`;

export const Info = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	position: relative;

	&:hover ${Button} {
		color: ${props =>
			mix(0.4, props.theme.textColor, props.theme.backgroundColor)};
	}
`;

export const CurrentTrack = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	/* max-width: 100%; */
`;

export const CurrentTrackNone = styled.span`
	padding: 1em;
	font-style: italic;
	display: block;
`;

export const CurrentTrackImage = styled.img`
	display: block;
	width: 2em;
	height: 2em;
`;

export const CurrentTrackName = styled.span`
	padding: 1em;
	display: block;
	flex: 1;
	/* white-space: nowrap; */
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const Buttons = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
`;

export const PlayerContainer = styled.div`
	width: 100%;
	margin-bottom: 0.4em;
	position: relative;

	&::before {
		content: '';
		display: block;
		padding-top: 56.25%;
	}

	${Container}.is-minimized & {
		position: absolute;
		width: 2em;
		height: 2em;
		top: 50%;
		left: 1em;
		transform: translateY(-50%);
		margin-bottom: 0;

		&::before {
			display: none;
		}
	}
`;

export const PlayerWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
`;

export const Queue = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	flex: 1;
	overflow-y: auto;
	scrollbar-color: ${props => mix(0.6, 'teal', props.theme.backgroundColor)}
		transparent;
	scrollbar-width: thin;
`;

export const QueueItem = styled.li`
	display: flex;
	align-items: center;

	&:hover ${Button} {
		color: ${props =>
			mix(0.4, props.theme.textColor, props.theme.backgroundColor)};
	}
`;

export const QueueItemImage = styled.img`
	width: 2em;
	height: 2em;
`;

export const QueueItemTitle = styled.span`
	display: block;
	padding: 0.6em 1em;
	flex: 1;
	align-items: center;
`;

export const QueueItemIsPlaying = styled.span`
	display: inline-block;
	margin-right: 0.3em;

	color: ${props =>
		mix(0.4, props.theme.textColor, props.theme.backgroundColor)};
`;

export const QueueItemButtons = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
`;

export const Progress = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	height: 2px;
	background: ${props => props.theme.accentColor};
	min-width: 2px;

	&::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: -2px;
		width: 2px;
		height: 2px;
		background: ${props => props.theme.accentColor};
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		right: 0;
		top: -2px;
		width: 2px;
		height: 2px;
		background: ${props => props.theme.accentColor};
	}
`;

import * as React from 'react';
import sortBy from 'lodash/sortBy';

import * as Editor from './../Editor/EditorStyle';
import hashtag from './../Editor/decorators/hashtag';
import { HASHTAG_REGEX } from './../Editor/decorators/hashtag';

// const TOPIC_PATTERN = /([^\w]|^)(#([\w\u0590-\u05ff]+))/g;
const URL_PATTERN = /((((https?|ftp):)?\/\/)|^|(\s))((?!(10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(\.\d{1,3}){2})(?!172\.(1[6-9]|2\d|3[0-1])(\.\d{1,3}){2})([1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(1?\d{1,2}|2[0-4]\d|25[0-5])){2}(\.([1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(([a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(\.([a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(\.([a-z\u00a1-\uffff]{2,})))(:\d{2,5})?([/?#]\S*)?/gi;

function displayUrl(url, limit = 80) {
	const strippedUrl = String(url)
		.replace(/^https?:\/\//, '')
		.replace(/\/$/, '');

	if (strippedUrl.length <= limit) {
		return strippedUrl;
	}

	return (
		strippedUrl.substring(0, 30) +
		'...' +
		strippedUrl.substring(strippedUrl.length - 20)
	);
}

export default function Body({ text }) {
	let replacements = [];
	let match;

	while ((match = HASHTAG_REGEX.exec(text)) !== null) {
		// sigh, if only we had positive lookbehind :)
		const start = match.index + match[1].length;
		replacements.push({
			start,
			end: start + match[2].length,
			replacement: (
				<hashtag.component key={match[2]} decoratedText={match[2]}>
					{match[2]}
				</hashtag.component>
			),
		});
	}

	while ((match = URL_PATTERN.exec(text)) !== null) {
		// sigh, if only we had positive lookbehind :)
		const spaceLength = (match[5] && match[5].length) || 0;
		const start = match.index + spaceLength;
		const end = start + (match[0].length - spaceLength);
		const url = text.substring(start, end);

		replacements.push({
			start,
			end,
			replacement: (
				<Editor.Link
					key={url}
					href={url}
					target="_blank"
					rel="noopener noreferrer"
				>
					{displayUrl(url)}
				</Editor.Link>
			),
		});
	}

	const sortedReplacements = sortBy(replacements, ['start']);

	const parts = [];
	let start = 0;

	sortedReplacements.forEach(info => {
		parts.push(text.substring(start, info.start));
		parts.push(info.replacement);

		start = info.end;
	});

	parts.push(text.substr(start));

	return <>{parts}</>;
}

import * as React from 'react';
import InfiniteScroller from 'react-infinite-scroller';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as navigationActions from './../../store/actions/navigation';
import * as notesActions from './../../store/actions/notes';
import scrollListener from './../../store/listeners/scroll';
import { notesListSelector } from './../../store/selectors/notes-list';

import EmptyNote from './../Note/EmptyNote';
import ErrorNote from './../Note/ErrorNote';
import GlobalHotkey from './../GlobalHotkey/GlobalHotkey';
import Note from './../Note/Note';
import hashtag from './../Editor/decorators/hashtag';

import ListIdContext from './../../contexts/ListIdContext';
import { makeNoteWithPlainText } from './../../libs/note-utils';

import * as s from './NoteListStyle';

class NoteList extends React.PureComponent {
	static contextType = ListIdContext;

	containerRef = React.createRef();
	unsubscribeScroll = null;

	componentDidMount() {
		this.props.notesActions.list(
			this.props.listId,
			this.props.notesState.searchNote,
		);

		this.unsubscribeScroll = scrollListener(this.props.listId, top => {
			if (this.containerRef.current) {
				this.containerRef.current.scrollBy({
					top,
					behavior: 'smooth',
				});
			}
		});
	}

	componentWillUnmount() {
		if (this.unsubscribeScroll) {
			this.unsubscribeScroll();
		}
	}

	loadMore = () => {
		this.props.notesActions.loadMore(this.props.listId);
	};

	onNoteClick = note => {
		this.props.navigationActions.set(this.props.listId, note.id);
		this.props.navigationActions.setList(this.props.listId);
	};

	onCtrlTopicPress = combo => {
		const index = parseInt(combo.substring(5), 10) - 1 || 0;

		if (!this.props.notesState.topics) {
			return;
		}

		const topic = this.props.notesState.topics[index];

		if (!topic) {
			return;
		}

		this.props.notesActions.setOrCreateList(
			this.context,
			makeNoteWithPlainText(`#${topic.title} `),
			false,
		);
	};

	renderEditor() {
		return (
			<EmptyNote
				listId={this.props.listId}
				desktop={this.props.desktop}
			/>
		);
	}

	renderHotTopics() {
		if (
			!this.props.notesState.topics ||
			this.props.notesState.topics.length === 0
		) {
			return null;
		}

		return (
			<s.TopicsContainer>
				<s.Topics key="TOPICS">
					{this.props.notesState.topics.map(topic => (
						<s.Topic key={topic.id}>
							<hashtag.component
								decoratedText={`#${topic.title}`}
							>
								{`#${topic.title}`}
							</hashtag.component>
						</s.Topic>
					))}
				</s.Topics>

				<GlobalHotkey
					code="ctrl+1"
					handler={this.onCtrlTopicPress}
					active={this.props.isSelectedList}
				/>
				<GlobalHotkey
					code="ctrl+1"
					handler={this.onCtrlTopicPress}
					active={this.props.isSelectedList}
				/>
				<GlobalHotkey
					code="ctrl+2"
					handler={this.onCtrlTopicPress}
					active={this.props.isSelectedList}
				/>
				<GlobalHotkey
					code="ctrl+3"
					handler={this.onCtrlTopicPress}
					active={this.props.isSelectedList}
				/>
				<GlobalHotkey
					code="ctrl+4"
					handler={this.onCtrlTopicPress}
					active={this.props.isSelectedList}
				/>
				<GlobalHotkey
					code="ctrl+5"
					handler={this.onCtrlTopicPress}
					active={this.props.isSelectedList}
				/>
				<GlobalHotkey
					code="ctrl+6"
					handler={this.onCtrlTopicPress}
					active={this.props.isSelectedList}
				/>
				<GlobalHotkey
					code="ctrl+7"
					handler={this.onCtrlTopicPress}
					active={this.props.isSelectedList}
				/>
				<GlobalHotkey
					code="ctrl+8"
					handler={this.onCtrlTopicPress}
					active={this.props.isSelectedList}
				/>
				<GlobalHotkey
					code="ctrl+9"
					handler={this.onCtrlTopicPress}
					active={this.props.isSelectedList}
				/>
			</s.TopicsContainer>
		);
	}

	renderNotes() {
		return this.props.notesList.map(note => (
			<ErrorNote key={note.id} note={note}>
				<Note
					key={note.id}
					note={note}
					small={this.props.small}
					desktop={this.props.desktop}
					selected={note.id === this.props.selectedNote}
					isSelectedList={this.props.isSelectedList}
					onClick={this.onNoteClick}
				/>
			</ErrorNote>
		));
	}

	renderLoader() {
		if (!this.props.notesState.isLoadingMore) {
			return false;
		}

		return <s.LoadMoreLoader>Digging deeper&hellip;</s.LoadMoreLoader>;
	}

	renderNoResults() {
		if (this.props.notesState.isLoading) {
			// return null;
		}

		if (this.props.notesList.length > 0) {
			return null;
		}

		return <s.NoResults>Nothing here&hellip;</s.NoResults>;
	}

	renderTheEnd() {
		if (
			this.props.notesState.isLoading ||
			this.props.notesState.isLoadingMore ||
			this.props.notesState.hasMore ||
			this.props.notesList.length === 0
		) {
			return null;
		}

		return <s.TheEnd>That's all, folks!</s.TheEnd>;
	}

	render() {
		const hasMore =
			!this.props.notesState.isLoading &&
			!this.props.notesState.isLoadingMore &&
			this.props.notesState.hasMore;

		return (
			<s.Container
				ref={this.containerRef}
				isSelectedList={this.props.isSelectedList}
			>
				<InfiniteScroller
					pageStart={0}
					loadMore={this.loadMore}
					hasMore={hasMore}
					loader={<React.Fragment key="LOADER" />}
					threshold={1000}
					useWindow={false}
					getScrollParent={() => this.containerRef.current}
				>
					<s.Notes key="NOTES">
						{this.renderEditor()}
						{this.renderHotTopics()}
						{this.renderNotes()}

						{this.renderLoader()}
						{this.renderNoResults()}
						{this.renderTheEnd()}

						<s.Spacer />
					</s.Notes>
				</InfiniteScroller>
			</s.Container>
		);
	}
}

export default connect(
	(state, props) => ({
		notesState: state.notes[props.listId],
		isSelectedList: state.navigation.selectedList === props.listId,
		selectedNote: state.navigation.selected[props.listId],
		...notesListSelector(props.listId, state),
	}),
	dispatch => ({
		notesActions: bindActionCreators(notesActions, dispatch),
		navigationActions: bindActionCreators(navigationActions, dispatch),
	}),
)(NoteList);

import { SETUP, SETUP_SUCCESS, SETUP_ERROR } from './../actions/setup';

const initialState = {
	setupIsLoading: true,
	setupError: null,
};

export default function setupReducer(state = initialState, action) {
	switch (action.type) {
		case SETUP:
			return {
				...state,
				setupIsLoading: true,
			};

		case SETUP_SUCCESS:
			return {
				...state,
				setupIsLoading: false,
			};

		case SETUP_ERROR:
			return {
				...state,
				setupIsLoading: false,
				setupError: action.payload.error,
			};

		default:
			return state;
	}
}

export const MUSIC_ADD_TO_QUEUE = 'MUSIC_ADD_TO_QUEUE';
export const MUSIC_PLAY_NOW = 'MUSIC_PLAY_NOW';
export const MUSIC_REMOVE_FROM_QUEUE = 'MUSIC_REMOVE_FROM_QUEUE';
export const MUSIC_CLEAR_QUEUE = 'MUSIC_CLEAR_QUEUE';
export const MUSIC_PLAY = 'MUSIC_PLAY';
export const MUSIC_PAUSE = 'MUSIC_PAUSE';
export const MUSIC_NEXT = 'MUSIC_NEXT';
export const MUSIC_PREVIOUS = 'MUSIC_PREVIOUS';
export const MUSIC_TOGGLE_MINIMIZE = 'MUSIC_TOGGLE_MINIMIZE';
export const MUSIC_PROGRESS = 'MUSIC_PROGRESS';
export const MUSIC_TOGGLE_REPEAT = 'MUSIC_TOGGLE_REPEAT';

export function addToQueue(link) {
	return {
		type: MUSIC_ADD_TO_QUEUE,
		payload: {
			link,
		},
	};
}

export function playNow(link) {
	return {
		type: MUSIC_PLAY_NOW,
		payload: {
			link,
		},
	};
}

export function removeFromQueue(link) {
	return {
		type: MUSIC_REMOVE_FROM_QUEUE,
		payload: {
			link,
		},
	};
}

export function clearQueue() {
	return {
		type: MUSIC_CLEAR_QUEUE,
	};
}

export function play() {
	return {
		type: MUSIC_PLAY,
	};
}

export function pause() {
	return {
		type: MUSIC_PAUSE,
	};
}

export function next() {
	return {
		type: MUSIC_NEXT,
	};
}

export function previous() {
	return {
		type: MUSIC_PREVIOUS,
	};
}

export function toggleMinimize() {
	return {
		type: MUSIC_TOGGLE_MINIMIZE,
	};
}

export function progress(progress) {
	return {
		type: MUSIC_PROGRESS,
		payload: {
			progress,
		},
	};
}

export function toggleRepeat() {
	return {
		type: MUSIC_TOGGLE_REPEAT,
	};
}

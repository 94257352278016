import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as focusActions from './../../store/actions/focus';
import * as navigationActions from './../../store/actions/navigation';
import * as notesActions from './../../store/actions/notes';
import * as scrollActions from './../../store/actions/scroll';
import * as usersActions from './../../store/actions/users';
import location from './../../store/units/location';

import GlobalHotkey from './../GlobalHotkey/GlobalHotkey';
import MusicPlayer from './../MusicPlayer/MusicPlayer';
import NoteList from './../NoteList/NoteList';
import Gallery from './../Gallery/Gallery';

import { makeEmptyNote, INITIAL_LIST_ID } from './../../libs/note-utils';
import ListIdContext from './../../contexts/ListIdContext';

import * as s from './DashboardStyle';

class Dashboard extends React.PureComponent {
	smallMediaQueryList = null;
	desktopMediaQueryList = null;

	state = {
		small: false,
		desktop: true,
	};

	componentDidMount() {
		// iphone se/5 is 320px wide
		const smallMediaQuery = '(max-width: 319px)';

		this.smallMediaQueryList = window.matchMedia(smallMediaQuery);
		this.smallMediaQueryList.addListener(this.onSmallMediaChange);

		this.onSmallMediaChange({ matches: this.smallMediaQueryList.matches });

		// desktop mode
		const desktopMediaQuery = '(min-width: 1000px)';

		this.desktopMediaQueryList = window.matchMedia(desktopMediaQuery);
		this.desktopMediaQueryList.addListener(this.onDesktopMediaChange);

		this.onDesktopMediaChange({
			matches: this.desktopMediaQueryList.matches,
		});

		// start up location service
		this.props.location();
	}

	componentWillUnmount() {
		this.smallMediaQueryList.removeListener(this.onSmallMediaChange);
	}

	onLogoutClick = () => {
		if (window.confirm('Sure?')) {
			this.props.usersActions.logout();
		}
	};

	onSlashPress = () => {
		this.props.focusActions.editor(this.props.currentListId);
	};

	onHomePress = () => {
		this.props.notesActions.clear(this.props.currentListId);
	};

	onRefreshPress = () => {
		this.props.notesActions.refresh(this.props.currentListId);
	};

	onSmallMediaChange = ({ matches }) => {
		this.setState({
			small: matches,
		});
	};

	onDesktopMediaChange = ({ matches }) => {
		this.setState({
			desktop: matches,
		});
	};

	onHalveDownPress = () => {
		this.props.scrollActions.halveDown(this.props.currentListId);
	};

	onHalveUpPress = () => {
		this.props.scrollActions.halveUp(this.props.currentListId);
	};

	onDownPress = () => {
		this.props.scrollActions.down(this.props.currentListId);
	};

	onUpPress = () => {
		this.props.scrollActions.up(this.props.currentListId);
	};

	onSelectedNext = () => {
		this.props.navigationActions.next();
	};

	onSelectedPrevious = () => {
		this.props.navigationActions.previous();
	};

	onSelectedFirst = () => {
		this.props.navigationActions.first();
		this.props.scrollActions.toTop(this.props.currentListId);
	};

	onSelectedLast = () => {
		this.props.navigationActions.last();
	};

	onSelectedNextList = () => {
		this.props.navigationActions.nextList();
	};

	onSelectedPreviousList = () => {
		this.props.navigationActions.previousList();
	};

	onCloseList = () => {
		if (this.props.currentListId === INITIAL_LIST_ID) {
			this.props.notesActions.clear(this.props.currentListId);
			this.props.focusActions.editor(this.props.currentListId);
		} else {
			this.props.notesActions.closeList(this.props.currentListId);
		}
	};

	onNewList = () => {
		this.props.notesActions.newList(makeEmptyNote());
	};

	renderHotkeys() {
		return (
			<React.Fragment>
				<GlobalHotkey code="g g" handler={this.onSelectedFirst} />
				<GlobalHotkey code="g h" handler={this.onHomePress} />
				<GlobalHotkey code="shift+g" handler={this.onSelectedLast} />
				<GlobalHotkey code="r" handler={this.onRefreshPress} />
				<GlobalHotkey code="ctrl+d" handler={this.onHalveDownPress} />
				<GlobalHotkey code="ctrl+u" handler={this.onHalveUpPress} />
				<GlobalHotkey code="space" handler={this.onDownPress} />
				<GlobalHotkey code="shift+space" handler={this.onUpPress} />

				<GlobalHotkey code="/" handler={this.onSlashPress} />

				<GlobalHotkey code="j" handler={this.onSelectedNext} />
				<GlobalHotkey code="k" handler={this.onSelectedPrevious} />
				<GlobalHotkey code="ctrl+l" handler={this.onSelectedNextList} />
				<GlobalHotkey
					code="ctrl+h"
					handler={this.onSelectedPreviousList}
				/>
				<GlobalHotkey code="x" handler={this.onCloseList} />
				<GlobalHotkey code="c" handler={this.onNewList} />

				<GlobalHotkey code="u o" handler={this.onLogoutClick} />
			</React.Fragment>
		);
	}

	renderNoteLists() {
		return Object.keys(this.props.notesState).map(listId => (
			<s.NoteListWrapper desktop={this.state.desktop} key={listId}>
				<ListIdContext.Provider value={listId}>
					<NoteList
						small={this.state.small}
						desktop={this.state.desktop}
						listId={listId}
					/>
				</ListIdContext.Provider>
			</s.NoteListWrapper>
		));
	}

	renderGallery() {
		if (!this.state.desktop) {
			return null;
		}

		return (
			<s.NoteListWrapper desktop={this.state.desktop} huge>
				<Gallery desktop={this.state.desktop} />
			</s.NoteListWrapper>
		);
	}

	render() {
		return (
			<s.Container>
				{this.renderHotkeys()}

				<s.NoteListContainer>
					<MusicPlayer desktop={this.state.desktop} />

					{this.renderNoteLists()}
					{this.renderGallery()}
				</s.NoteListContainer>
			</s.Container>
		);
	}
}

export default connect(
	state => ({
		notesState: state.notes,
		currentListId: state.navigation.selectedList,
	}),
	dispatch => ({
		navigationActions: bindActionCreators(navigationActions, dispatch),
		notesActions: bindActionCreators(notesActions, dispatch),
		location: bindActionCreators(location, dispatch),
		scrollActions: bindActionCreators(scrollActions, dispatch),
		focusActions: bindActionCreators(focusActions, dispatch),
		usersActions: bindActionCreators(usersActions, dispatch),
	}),
)(Dashboard);

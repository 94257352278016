import update from 'immutability-helper';
import findIndex from 'lodash/findIndex';

import {
	MUSIC_ADD_TO_QUEUE,
	MUSIC_PLAY_NOW,
	MUSIC_REMOVE_FROM_QUEUE,
	MUSIC_CLEAR_QUEUE,
	MUSIC_PLAY,
	MUSIC_PAUSE,
	MUSIC_NEXT,
	MUSIC_PREVIOUS,
	MUSIC_TOGGLE_MINIMIZE,
	MUSIC_PROGRESS,
	MUSIC_TOGGLE_REPEAT,
} from './../actions/music';

const initialState = {
	queue: [],
	current: null,
	isPlaying: false,
	isMinimized: false,
	repeat: 'all',
	progress: null,
};

export default function music(state = initialState, action) {
	switch (action.type) {
		case MUSIC_ADD_TO_QUEUE: {
			const index = findIndex(state.queue, [
				'url',
				action.payload.link.url,
			]);

			if (index !== -1) {
				return state;
			}

			return update(state, {
				queue: {
					$push: [action.payload.link],
				},
			});
		}

		case MUSIC_PLAY_NOW: {
			const link = action.payload.link;
			const index = findIndex(state.queue, ['url', link.url]);

			return update(state, {
				isPlaying: {
					$set: true,
				},
				queue: {
					$apply: queue => {
						if (index !== -1) {
							return queue;
						}

						return update(queue, {
							$push: [link],
						});
					},
				},
				current: {
					$set: action.payload.link,
				},
				progress: {
					$set: null,
				},
			});
		}

		case MUSIC_REMOVE_FROM_QUEUE: {
			const index = findIndex(state.queue, [
				'url',
				action.payload.link.url,
			]);
			const isCurrent =
				state.current && state.current.url === action.payload.link.url;

			if (index === -1) {
				return state;
			}

			return update(state, {
				queue: {
					$splice: [[index, 1]],
				},
				current: {
					$apply: current => {
						if (isCurrent) {
							return state.queue[index + 1] || null;
						}

						return current;
					},
				},
				progress: {
					$apply: progress => (isCurrent ? null : progress),
				},
			});
		}

		case MUSIC_CLEAR_QUEUE:
			return update(state, {
				queue: {
					$set: [],
				},
				current: {
					$set: null,
				},
				progress: {
					$set: null,
				},
			});

		case MUSIC_PLAY:
			return update(state, {
				isPlaying: {
					$set: state.queue.length > 0,
				},
				current: {
					$apply: current => {
						return current || state.queue[0];
					},
				},
			});

		case MUSIC_PAUSE:
			return update(state, {
				isPlaying: {
					$set: false,
				},
			});

		case MUSIC_NEXT: {
			if (!state.current) {
				return state;
			}

			const index = findIndex(state.queue, ['url', state.current.url]);

			if (index === -1) {
				return state;
			}

			const newIndex = index === state.queue.length - 1 ? 0 : index + 1;

			return update(state, {
				current: {
					$set: state.queue[newIndex],
				},
			});
		}

		case MUSIC_PREVIOUS: {
			if (!state.current) {
				return state;
			}

			const index = findIndex(state.queue, ['url', state.current.url]);

			if (index === -1) {
				return state;
			}

			const newIndex = index === 0 ? state.queue.length - 1 : index - 1;

			return update(state, {
				current: {
					$set: state.queue[newIndex],
				},
			});
		}

		case MUSIC_TOGGLE_MINIMIZE:
			return update(state, {
				isMinimized: {
					$set: !state.isMinimized,
				},
			});

		case MUSIC_PROGRESS:
			return update(state, {
				progress: {
					$set: action.payload.progress,
				},
			});

		case MUSIC_TOGGLE_REPEAT: {
			let repeat;

			if (state.repeat === 'all') {
				repeat = 'one';
			} else {
				repeat = 'all';
			}

			return {
				...state,
				repeat,
			};
		}

		default:
			return state;
	}
}

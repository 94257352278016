import styled, { css } from 'styled-components/macro';
import { math } from 'polished';

export const Container = styled.div`
	padding-top: 4px;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const NoteListContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex: 1;
	position: relative;
`;

const nonDesktop = css`
	position: absolute;
	top: 0;
	left: 50%;
	height: 100%;
	transform: translateX(-50%);
`;

export const NoteListWrapper = styled.div`
	position: relative;
	z-index: 1;
	max-width: ${props =>
		math(`${props.theme.width} * ${props.huge ? 20 : 1}`)};
	width: 100%;

	// same as global style
	background-color: ${props => props.theme.backgroundColor};
	transition: background-color 0.4s, color 0.4s;

	${props => (!props.desktop ? nonDesktop : '')};
`;

import * as React from 'react';
import update from 'immutability-helper';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FiXCircle, FiXSquare, FiSave, FiRefreshCw } from 'react-icons/fi';

import * as notesActions from './../../store/actions/notes';
import * as scrollActions from './../../store/actions/scroll';
import focusListener from './../../store/listeners/focus';
import notesSave from './../../store/units/notes-save';

import { isEmpty, INITIAL_LIST_ID } from './../../libs/note-utils';

import Editor from './../Editor/Editor';

import * as s from './NoteStyle';

class EmptyNote extends React.Component {
	editor = null;
	lastQ = '';
	unsubscribeFocus = null;

	componentDidMount() {
		this.unsubscribeFocus = focusListener(this.props.listId, () => {
			if (this.editor) {
				this.editor.focus();
			}
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.notesIsClearingState && this.editor) {
			this.editor.setBody('');
		}

		if (this.props.notesIsResettingState && this.editor) {
			this.editor.setBody(this.props.notesSearchNoteState.body);
		}
	}

	componentWillUnmount() {
		if (this.unsubscribeFocus) {
			this.unsubscribeFocus();
		}
	}

	onSaveClick = () => {
		if (this.isBodyEmpty()) {
			return;
		}

		if (!this.editor) {
			return;
		}

		this.editor.triggerSave();
	};

	onRefreshClick = () => {
		this.props.notesActions.refresh(this.props.listId);
	};

	onClearClick = () => {
		this.props.notesActions.clear(this.props.listId);
	};

	onCloseListClick = () => {
		this.props.notesActions.closeList(this.props.listId);
	};

	onEditorSave = body => {
		const note = update(this.props.notesSearchNoteState, {
			body: {
				$set: body,
			},
		});

		this.props.notesSave(this.props.listId, note);
	};

	onEditorChange = body => {
		const trimmedPlainText = body.trim();

		if (this.lastQ === trimmedPlainText) {
			return;
		}

		// scroll the editor into view
		this.scrollToTop();

		const note = update(this.props.notesSearchNoteState, {
			body: {
				$set: trimmedPlainText,
			},
		});

		this.lastQ = trimmedPlainText;

		this.props.notesActions.search(this.props.listId, note);
	};

	isBodyEmpty() {
		return isEmpty(this.props.notesSearchNoteState);
	}

	scrollToTop() {
		this.props.scrollActions.toTop(this.props.listId);
	}

	renderCloseListButton() {
		if (this.props.listId === INITIAL_LIST_ID) {
			return null;
		}

		return (
			<s.Action onClick={this.onCloseListClick}>
				<FiXCircle />
				Close
			</s.Action>
		);
	}

	renderRefreshButton() {
		return (
			<s.Action onClick={this.onRefreshClick}>
				<FiRefreshCw />
				Refresh
			</s.Action>
		);
	}

	renderClearButton() {
		return (
			<s.Action onClick={this.onClearClick}>
				<FiXSquare />
				Clear
			</s.Action>
		);
	}

	renderSaveButton() {
		return (
			<s.Action onClick={this.onSaveClick}>
				<FiSave />
				Save
			</s.Action>
		);
	}

	renderActions() {
		return (
			<s.Meta>
				<s.CreatedAt />

				<s.Actions>
					{this.renderCloseListButton()}
					{this.renderRefreshButton()}
					{this.renderClearButton()}
					{this.renderSaveButton()}
				</s.Actions>
			</s.Meta>
		);
	}

	render() {
		return (
			<s.EmptyNoteContainer desktop={this.props.desktop}>
				<s.Container as="div">
					<s.Body>
						<Editor
							ref={ref => {
								this.editor = ref;
							}}
							onSave={this.onEditorSave}
							onChange={this.onEditorChange}
							note={this.props.notesSearchNoteState}
							placeholder="Enter text to search&hellip;"
							emptyOnCreate
						/>
					</s.Body>

					{this.renderActions()}

					<s.Loader
						isLoading={this.props.notesState.isLoading}
						createNote
					/>
				</s.Container>
			</s.EmptyNoteContainer>
		);
	}
}

export default connect(
	(state, props) => ({
		notesState: state.notes[props.listId],
		notesSearchNoteState: state.notes[props.listId].searchNote,
		notesIsClearingState: state.notes[props.listId].isClearing,
		notesIsResettingState: state.notes[props.listId].isResetting,
		isSelectedList: state.navigation.selectedList === props.listId,
	}),
	dispatch => ({
		notesSave: bindActionCreators(notesSave, dispatch),
		notesActions: bindActionCreators(notesActions, dispatch),
		scrollActions: bindActionCreators(scrollActions, dispatch),
	}),
)(EmptyNote);

export function getClosestThumbnail(attachment, width) {
	let widthDiff = null;

	return (attachment?.thumbnails || []).reduce((carry, thumbnail) => {
		if (carry === null || widthDiff === null) {
			widthDiff = Math.abs(thumbnail.width - width);
			return thumbnail;
		} else if (Math.abs(thumbnail.width - width) < widthDiff) {
			widthDiff = Math.abs(thumbnail.width - width);
			return thumbnail;
		} else {
			return carry;
		}
	}, null)?.url;
}

export function biggestThumbnail(attachment) {
	let biggestWidth = 0;

	try {
		const url = new URL(attachment?.url || '');

		if (url.pathname.endsWith('.gif')) {
			// TODO use the actual GIF, maybe? My example was 17mb...
			// return attachment.url;
		}
	} catch {}

	return (attachment?.thumbnails || []).reduce((carry, thumbnail) => {
		if (thumbnail.width > biggestWidth) {
			return thumbnail;
		}

		return carry;
	}, null)?.url;
}

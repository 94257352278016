export const SETUP = 'SETUP';
export const SETUP_SUCCESS = 'SETUP_SUCCESS';
export const SETUP_ERROR = 'SETUP_ERROR';

export function setup() {
	return {
		type: SETUP,
	};
}

export function setupSuccess() {
	return {
		type: SETUP_SUCCESS,
	};
}

export function setupError(error) {
	return {
		type: SETUP_ERROR,
		payload: {
			error,
		},
	};
}

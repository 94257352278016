import { defaultThemeName } from './../../theme';

import {
	CLIENT_SETINGS_SET_MUTED,
	CLIENT_SETTINGS_SET_THEME,
} from './../actions/client-settings';

const initialState = {
	themeName: defaultThemeName,
	muted: true,
};

export default function clientSettingsReducer(state = initialState, action) {
	switch (action.type) {
		case CLIENT_SETTINGS_SET_THEME:
			return {
				...state,
				themeName: action.payload.themeName,
			};

		case CLIENT_SETINGS_SET_MUTED:
			return {
				...state,
				muted: action.payload.muted,
			};

		default:
			return state;
	}
}

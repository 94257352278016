import { FOCUS_EDITOR } from './../actions/focus';

const listeners = {};

export default function focusListener(listId, callback) {
	listeners[listId] = callback;

	return () => {
		delete listeners[listId];
	};
}

function broadcastFocus(listId) {
	if (listeners[listId]) {
		listeners[listId]();
	}
}

export function middleware() {
	return next => action => {
		const returnValue = next(action);

		if (action.type === FOCUS_EDITOR) {
			broadcastFocus(action.payload.listId);
		}

		return returnValue;
	};
}

import { all } from 'redux-saga/effects';

import setup from './setup';
import users from './users';
import notes from './notes';
import navigation from './navigation';

import notesEdit from './../units/notes-edit';
import notesDelete from './../units/notes-delete';
import notesSave from './../units/notes-save';
import notesArchive from './../units/notes-archive';
import notesUnarchive from './../units/notes-unarchive';
import notesPin from './../units/notes-pin';
import notesUnpin from './../units/notes-unpin';
import notesSetReminder from './../units/notes-set-reminder';
import notesClearReminder from './../units/notes-clear-reminder';

import location from './../units/location';

export default function* rootSaga() {
	yield all([
		setup(),
		users(),
		navigation(),
		notes(),

		notesSave.saga(),
		notesEdit.saga(),
		notesDelete.saga(),
		notesArchive.saga(),
		notesUnarchive.saga(),
		notesPin.saga(),
		notesUnpin.saga(),
		notesSetReminder.saga(),
		notesClearReminder.saga(),

		location.saga(),
	]);
}

import styled from 'styled-components/macro';
import { mix } from 'polished';

export const Container = styled.div`
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	position: absolute;
	top: 0;
	left: 0;
	border-top: 2px solid
		${props =>
			props.isSelectedList ? props.theme.accentColor : 'transparent'};

	-webkit-overflow-scrolling: touch;
	scrollbar-color: ${props =>
			mix(0.3, props.theme.accentColor, props.theme.backgroundColor)}
		transparent;
	scrollbar-width: thin;
	display: flex;
	flex-direction: column;

	> div {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
`;

export const Notes = styled.ul`
	width: 100%;
	margin: 0 auto;
	list-style: none;
	padding: 0 1em;
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const TopicsContainer = styled.li`
	padding: 1em 0;
	overflow: hidden;
	border-bottom: 1px solid ${props => props.theme.borderColor};
`;

export const Topics = styled.ul`
	display: flex;
	gap: 1em;
	list-style: none;
	padding: 0;
	margin: 0;
`;

export const Topic = styled.li`
	color: ${props => props.theme.textColor};

	a {
		color: inherit;
	}
`;

export const Spacer = styled.li`
	flex: 1;
`;

export const Note = styled.li``;

export const EditorWrapper = styled.li`
	border-bottom: 1px solid ${props => props.theme.borderColor};
	padding: 1em 0;
`;

export const LoadMoreLoader = styled.li`
	text-align: center;
	padding: 2em;
	font-style: italic;
`;

export const NoResults = styled.li`
	text-align: center;
	padding: 2em;
	font-style: italic;
`;

export const TheEnd = styled.li`
	text-align: center;
	padding: 2em;
	font-style: italic;
`;

import * as React from 'react';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';

import * as s from './FormStyle';

import { Validator } from './Validator';

export * from './Input';
export * from './Validator';

function isInvalidChild(child) {
	return !child || typeof child === 'string';
}

export default class Form extends React.PureComponent {
	static defaultProps = {
		errors: [],
		onSubmit: () => {},
		submitLabel: 'Submit',
	};

	constructor(props) {
		super(props);

		const values = {};

		React.Children.forEach(props.children, child => {
			if (isInvalidChild(child)) {
				return;
			}

			const { name, initialValue } = child.props;

			if (initialValue) {
				values[name] = initialValue;
			}
		});

		this.state = {
			values,
			errors: [],
		};
	}

	onInputChange(name, onChange, { target }) {
		let value;

		if (target.type === 'checkbox') {
			value = target.checked;
		} else {
			value = target.value;
		}

		onChange(value);

		this.setState(state => ({
			values: {
				...state.values,
				[name]: value,
			},
		}));
	}

	onSubmit = ev => {
		ev.preventDefault();

		if (this.validateForm()) {
			this.props.onSubmit(this.state.values);
		}
	};

	validateForm() {
		const messages = filter(
			React.Children.map(this.props.children, child => {
				if (isInvalidChild(child)) {
					return null;
				}

				return this.validateField(child, {
					mustBeTouched: false,
				});
			}),
		);

		this.setState({
			errors: messages,
		});

		return messages.length === 0;
	}

	validateField(child, { mustBeTouched = true } = {}) {
		const { name } = child.props;

		const validators = filter(
			React.Children.map(child.props.children, c => {
				if (isInvalidChild(c)) {
					return null;
				}

				if (
					c.type === Validator ||
					c.type.displayName === 'Validator'
				) {
					return {
						validate: c.props.validate,
						message: c.props.message,
					};
				}

				return null;
			}),
		);

		const touched = typeof this.state.values[name] !== 'undefined';
		const errorMessage =
			((mustBeTouched && touched) || !mustBeTouched) &&
			reduce(
				validators,
				(acc, v) => {
					return (
						acc ||
						(!v.validate(this.state.values[name]) && v.message)
					);
				},
				null,
			);

		return errorMessage;
	}

	renderErrors() {
		const errors = [];

		if (this.props.errors.length > 0) {
			errors.push(...this.props.errors);
		}

		if (this.state.errors.length > 0) {
			errors.push(...this.state.errors);
		}

		if (!errors.length) {
			return null;
		}

		return (
			<s.ErrorContainer>
				<s.ErrorTitle>Error</s.ErrorTitle>
				<s.Errors>
					{errors.map(error => (
						<s.ErrorMessage key={error}>{error}</s.ErrorMessage>
					))}
				</s.Errors>
			</s.ErrorContainer>
		);
	}

	renderFields() {
		return React.Children.map(this.props.children, (child, i) => {
			if (isInvalidChild(child)) {
				return null;
			}

			const {
				type,
				name,
				label,
				placeholder,
				helpText,
				onChange,
				innerRef,
			} = child.props;
			const errorMessage = this.validateField(child);

			return this.renderField({
				type,
				name,
				placeholder,
				label,
				helpText,
				errorMessage,
				autoFocus: i === 0,
				onChange,
				innerRef,
			});
		});
	}

	renderField({
		name,
		label,
		placeholder,
		errorMessage,
		helpText,
		autoFocus,
		onChange,
		type = 'text',
		innerRef,
	}) {
		const isCheckbox = type === 'checkbox';
		let value;

		if (isCheckbox) {
			value = '1';
		} else {
			value = this.state.values[name] || '';
		}

		return (
			<s.InputWrapper>
				<s.Label>
					{!isCheckbox && label}

					<s.Input
						type={type}
						name={name}
						placeholder={
							placeholder !== undefined ? placeholder : label
						}
						value={value}
						onChange={this.onInputChange.bind(this, name, onChange)}
						autoFocus={autoFocus}
						ref={innerRef}
					/>

					{isCheckbox && label}
				</s.Label>

				{helpText && <s.HelpText>{helpText}</s.HelpText>}

				{errorMessage && <s.ErrorHint>{errorMessage}</s.ErrorHint>}
			</s.InputWrapper>
		);
	}

	renderSubmit() {
		return (
			<s.ButtonWrapper>
				<s.Submit type="submit" onClick={this.onSubmit}>
					{this.props.submitLabel}
				</s.Submit>
			</s.ButtonWrapper>
		);
	}

	render() {
		return (
			<s.Container method="post" onSubmit={this.onSubmit}>
				{this.renderErrors()}
				{this.renderFields()}
				{this.renderSubmit()}
			</s.Container>
		);
	}
}

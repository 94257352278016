import styled from 'styled-components/macro';

import * as NoteStyle from './../Note/NoteStyle';

export const Container = styled.div`
	flex: 1;
`;

export const Hashtag = styled.a`
	font-weight: 600;
	text-decoration: none;
	letter-spacing: -0.3px;
	display: inline-block;
	transition: all 0.3s;
	display: inline-flex;
	gap: 0.3em;

	&:hover {
		opacity: 0.7;
	}

	${NoteStyle.Action} {
		margin: 0;
	}
`;

export const Link = styled.a`
	text-decoration: underline;
`;

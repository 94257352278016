import * as axios from 'axios';
import { parse } from 'date-fns';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

// const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSX";
const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSSSS";

function getStore() {
	return require('./store').default;
}

function request(method, path, data, options = {}) {
	const state = getStore().getState();
	const token = state.users.token;

	const headers = {};

	if (token) {
		headers['Authorization'] = `Bearer ${token.token}`;
	}

	let params = {};

	if (method === 'GET') {
		params = data;
		data = null;
	}

	return axios({
		method,
		url: baseUrl + path,
		data,
		params,
		headers,
		...options,
	});
}

function transformJson(data) {
	if (typeof data === 'string') {
		try {
			data = JSON.parse(data);
		} catch (e) {
			/* Ignore */
		}
	}

	return data;
}

// probably need a way to make this scale
function transformSingle(note) {
	if (note.reminder) {
		note.reminder.at = parse(note.reminder.at, dateFormat, new Date());
	}

	if (note.archived_at) {
		note.archived_at = parse(note.archived_at, dateFormat, new Date());
	}

	if (note.pinned_at) {
		note.pinned_at = parse(note.pinned_at, dateFormat, new Date());
	}

	if (note.created_at) {
		note.created_at = parse(note.created_at, dateFormat, new Date());
	}

	return note;
}

function transformMultiple(data) {
	return {
		...data,
		// notes: data.notes.map(transformSingle),
		thoughts: data.thoughts.map(transformSingle),
	};
}

export function login(email, password) {
	// const path = '/v1/users/token';
	// const data = {
	// 	auth: {
	// 		email,
	// 		password,
	// 	},
	// };
	const path = '/login';
	const data = {
		username: email,
		password,
	};

	return request('POST', path, data);
}

export function token() {
	// return request('GET', '/v1/users/token');
	return request('GET', '/token');
}

export function me() {
	// return request('GET', '/v1/users/me');
	return request('GET', '/me');
}

export function notesList(q, cursor) {
	const params = {};

	if (q) {
		params.q = q;
	}

	if (cursor) {
		params.cursor = cursor;
	}

	return request('GET', '/thoughts', params, {
		transformResponse: [transformJson, transformMultiple],
	});
}

export function notesArchive(noteId) {
	return request('PUT', `/thoughts/${noteId}/archive`, null, {
		transformResponse: [transformJson, transformSingle],
	});
}

export function notesUnarchive(noteId) {
	return request('PUT', `/thoughts/${noteId}/unarchive`, null, {
		transformResponse: [transformJson, transformSingle],
	});
}

export function notesPin(noteId) {
	return request('PUT', `/thoughts/${noteId}/pin`, null, {
		transformResponse: [transformJson, transformSingle],
	});
}

export function notesUnpin(noteId) {
	return request('PUT', `/thoughts/${noteId}/unpin`, null, {
		transformResponse: [transformJson, transformSingle],
	});
}

export function notesSave(body, location) {
	return request(
		'POST',
		'/thoughts',
		{
			body,
			location,
		},
		{
			transformResponse: [transformJson, transformSingle],
		},
	);
}

export function notesEdit(noteId, body) {
	return request(
		'PATCH',
		`/thoughts/${noteId}`,
		{
			body,
		},
		{
			transformResponse: [transformJson, transformSingle],
		},
	);
}

export function notesDelete(noteId) {
	return request('DELETE', `/thoughts/${noteId}`);
}

export function notesSetReminder(noteId, at) {
	return request(
		'POST',
		`/v1/notes/${noteId}/reminders`,
		{
			reminder: {
				at,
			},
		},
		{
			transformResponse: [transformJson, transformSingle],
		},
	);
}

export function notesClearReminder(noteId) {
	return request('DELETE', `/v1/notes/${noteId}/reminders`, null, {
		transformResponse: [transformJson, transformSingle],
	});
}

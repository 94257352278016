export const FOCUS_EDITOR = 'FOCUS_EDITOR';

export function editor(listId) {
	return {
		type: FOCUS_EDITOR,
		payload: {
			listId,
		},
	};
}

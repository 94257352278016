import styled from 'styled-components/macro';
import { mix } from 'polished';

export const Container = styled.div`
	padding: 0 1em;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	position: absolute;
	top: 0;
	left: 0;
	color: inherit;

	-webkit-overflow-scrolling: touch;
	scrollbar-color: ${props => mix(0.4, 'teal', props.theme.backgroundColor)}
		transparent;
	scrollbar-width: thin;
`;

export const ListWrapper = styled.div`
	margin-bottom: 1em;
`;

export const Wrapper = styled.ul`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
	list-style: none;
`;

// motion.li wraps this element
export const Item = styled.div`
	position: relative;

	&::after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}
`;

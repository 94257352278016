import styled from 'styled-components/macro';
import { transparentize } from 'polished';

export const Container = styled.div`
	position: ${props => (props.cover ? 'absolute' : 'fixed')};
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	background-color: ${props => {
		switch (true) {
			case props.transparent:
				return 'transparent';
			case props.opaque:
				return props.theme.backgroundColor;
			default:
				return transparentize(0.5, props.theme.backgroundColor);
		}
	}};
`;

export const Text = styled.p`
	margin: 1em auto 0;
	max-width: 20em;
	text-align: center;
`;

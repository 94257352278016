export const SCROLL_TO_TOP = 'SCROLL_TO_TOP';
export const SCROLL_HALVE_UP = 'SCROLL_HALVE_UP';
export const SCROLL_HALVE_DOWN = 'SCROLL_HALVE_DOWN';
export const SCROLL_UP = 'SCROLL_UP';
export const SCROLL_DOWN = 'SCROLL_DOWN';

export function toTop(listId) {
	return {
		type: SCROLL_TO_TOP,
		payload: {
			listId,
		},
	};
}

export function halveUp(listId) {
	return {
		type: SCROLL_HALVE_UP,
		payload: {
			listId,
		},
	};
}

export function halveDown(listId) {
	return {
		type: SCROLL_HALVE_DOWN,
		payload: {
			listId,
		},
	};
}

export function up(listId) {
	return {
		type: SCROLL_UP,
		payload: {
			listId,
		},
	};
}

export function down(listId) {
	return {
		type: SCROLL_DOWN,
		payload: {
			listId,
		},
	};
}

import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';

import { USERS_LOGOUT } from './../actions/users';

function customizer(objValue, srcValue) {
	// completely replace arrays
	if (isArray(objValue)) {
		return srcValue;
	}

	return undefined;
}

const initialState = {};

export default function byId(state = initialState, action = {}) {
	// clear all entities when logging out
	if (action.type === USERS_LOGOUT) {
		return initialState;
	}

	if (action.payload && action.payload.entities) {
		return mergeWith({}, state, action.payload.entities, customizer);
	}

	return state;
}

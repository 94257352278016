import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as usersActions from './../../store/actions/users';

import Form, { Input, Validator } from './../Form/Form';

import * as s from './LoginStyle';

const config = {
	LOGIN_USERNAME: '',
	LOGIN_PASSWORD: '',
};

const EMAIL_ADDRESS_REGEX = /^[^@]+@[^@]+$/;

class Login extends React.PureComponent {
	onSubmit = values => {
		this.props.usersActions.login(values.email, values.password);
	};

	render() {
		const errors = [];

		if (this.props.usersState.tokenError) {
			errors.push(this.props.usersState.tokenError);
		}

		return (
			<s.Container>
				<s.Wrapper>
					<s.Title>Mindvau!t</s.Title>

					<Form
						errors={errors}
						onSubmit={this.onSubmit}
						submitLabel="Login"
					>
						<Input
							type="email"
							name="email"
							label="Email address"
							placeholder=""
							initialValue={config.LOGIN_USERNAME}
						>
							<Validator
								validate={text => !!text}
								message="Valid email address is missing"
							/>
						</Input>

						<Input
							type="password"
							name="password"
							label="Password"
							placeholder=""
							initialValue={config.LOGIN_PASSWORD}
						>
							<Validator
								validate={text => !!text && text.length >= 6}
								message="Valid password is missing"
							/>
						</Input>
					</Form>
				</s.Wrapper>
			</s.Container>
		);
	}
}

export default connect(
	state => ({
		usersState: state.users,
	}),
	dispatch => ({
		usersActions: bindActionCreators(usersActions, dispatch),
	}),
)(Login);

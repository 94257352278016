import { call, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { createSingle } from './redux-units';
import * as remote from './../../remote';

import { notesSchema } from './../schemas/notes';

export default createSingle('NOTES_EDIT', function*(success, error, note) {
	try {
		const response = yield call(remote.notesEdit, note.id, note.body);
		const data = response.data;

		const normalizedData = normalize(data, notesSchema);

		yield put(
			success({
				...normalizedData,
				note: response.data,
			}),
		);
	} catch (e) {
		yield put(error(note, e.message));
	}
});

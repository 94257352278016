import { takeLatest, put, all, select, call } from 'redux-saga/effects';

import { SETUP, setupSuccess, setupError } from './../actions/setup';

import { loginSuccess as usersLoginSuccess } from './../actions/users';

import { me as usersMe } from './../sagas/users';

import * as remote from './../../remote';

const tokenSelector = state => state.users.token;

function* setup() {
	try {
		const token = yield select(tokenSelector);

		if (token) {
			yield* usersMe();

			// fetch a fresh token
			const response = yield call(remote.token);
			yield put(usersLoginSuccess(response.data));
		}

		yield put(setupSuccess());
	} catch (e) {
		yield put(setupError(e.message));
	}
}

export default function* setupSaga() {
	yield all([takeLatest(SETUP, setup)]);
}

import React from 'react';

import * as s from './LoadingOverlayStyle';

export default class LoadingOverlay extends React.PureComponent {
	static defaultProps = {
		isVisible: false,
		cover: true,
		opaque: false,
		transparent: false,
	};

	render() {
		if (!this.props.isVisible) {
			return null;
		}

		return (
			<s.Container
				cover={this.props.cover}
				opaque={this.props.opaque}
				transparent={this.props.transparent}
			>
				{this.props.text && <s.Text>{this.props.text}</s.Text>}
			</s.Container>
		);
	}
}

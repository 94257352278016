import produce from 'immer';

import {
	NAVIGATION_SET,
	NAVIGATION_SET_LIST,
	NAVIGATION_CLOSE_LIST,
} from './../actions/navigation';

import { NOTES_NEW_LIST } from './../actions/notes';
import { INITIAL_LIST_ID } from './../../libs/note-utils';

const initialState = {
	selectedList: INITIAL_LIST_ID,
	selected: {},
};

export default function navigationReducer(state = initialState, action) {
	switch (action.type) {
		case NAVIGATION_SET:
			return produce(state, draft => {
				draft.selected[action.payload.listId] = action.payload.selected;
			});

		case NAVIGATION_SET_LIST:
			return produce(state, draft => {
				draft.selectedList = action.payload.listId;
			});

		case NOTES_NEW_LIST:
			return produce(state, draft => {
				draft.selectedList = action.payload.listId;
				draft.selected[action.payload.listId] = null;
			});

		case NAVIGATION_CLOSE_LIST:
			return produce(state, draft => {
				delete draft.selected[action.payload.listId];
			});

		default:
			return state;
	}
}

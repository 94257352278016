import * as React from 'react';
import { FiPlay } from 'react-icons/fi';

import Blur from './../Note/Blur';
import Lightbox from './../Lightbox/Lightbox';
import {
	biggestThumbnail,
	getClosestThumbnail,
} from './../../libs/attachment-utils';

import * as s from './MediumStyle';

function Image({ link, nsfw, contain }) {
	if (!link.attachment) {
		return null;
	}

	return (
		<Lightbox imageSrc={biggestThumbnail(link.attachment)}>
			<Blur blurHash={link.attachment.blur_hash} />
			<s.Image
				src={getClosestThumbnail(link.attachment, 300)}
				alt=""
				nsfw={nsfw}
				$contain={contain}
			/>
		</Lightbox>
	);
}

function Video({ link, nsfw, muted, contain }) {
	if (!link.attachment) {
		return null;
	}

	const src = getClosestThumbnail(link.attachment, 300);
	const videoSrc = link.attachment.url;

	return (
		<Lightbox videoSrc={videoSrc} posterSrc={src} muted={muted}>
			<Blur blurHash={link.attachment.blur_hash} />
			<s.Image src={src} alt="" nsfw={nsfw} $contain={contain} />
			<FiPlay />
		</Lightbox>
	);
}

export default function Medium({ link, nsfw, muted, contain }) {
	switch (link.kind) {
		case 'IMAGE':
			return <Image link={link} nsfw={nsfw} contain={contain} />;

		case 'VIDEO':
			return (
				<Video
					link={link}
					nsfw={nsfw}
					muted={muted}
					contain={contain}
				/>
			);

		default:
			return null;
	}
}

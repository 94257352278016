import { takeLatest } from 'redux-saga/effects';

export function createSingle(actionTypePrefix, action, options) {
	const ACTION_TYPES = Object.freeze({
		START: 'redux-units/' + actionTypePrefix,
		SUCCESS: 'redux-units/' + actionTypePrefix + '_SUCCESS',
		ERROR: 'redux-units/' + actionTypePrefix + '_ERROR',
	});

	const initialState = {
		isLoading: {},
		error: {},
		...((options && options.initialPayload) || {}),
	};

	function reducer(state = initialState, action) {
		switch (action.type) {
			case ACTION_TYPES.START:
				return {
					...state,
					isLoading: {
						...state.isLoading,
						[action.item.id]: true,
					},
				};

			case ACTION_TYPES.SUCCESS:
				return {
					...state,
					isLoading: {
						...state.isLoading,
						[action.item.id]: false,
					},
					error: {
						...state.isLoading,
						[action.item.id]: null,
					},
					...action.payload,
				};

			case ACTION_TYPES.ERROR:
				return {
					...state,
					isLoading: {
						...state.isLoading,
						[action.item.id]: false,
					},
					error: {
						...state.isLoading,
						[action.item.id]: action.error,
					},
				};

			default:
				if (options && options.additionalReducer) {
					return options.additionalReducer(state, action);
				}

				return state;
		}
	}

	function actionStart(item, ...args) {
		return {
			type: ACTION_TYPES.START,
			item,
			args,
		};
	}

	function actionSuccess(item, payload) {
		return {
			type: ACTION_TYPES.SUCCESS,
			item,
			payload,
		};
	}

	function actionError(item, error) {
		return {
			type: ACTION_TYPES.ERROR,
			item,
			error,
		};
	}

	function* runAction({ item, args }) {
		yield action(
			actionSuccess.bind(null, item),
			actionError.bind(null, item),
			item,
			...args,
		);
	}

	function* saga() {
		yield takeLatest(ACTION_TYPES.START, runAction);
	}

	actionStart.reducer = reducer;
	actionStart.saga = saga;
	actionStart.START = ACTION_TYPES.START;
	actionStart.SUCCESS = ACTION_TYPES.SUCCESS;
	actionStart.ERROR = ACTION_TYPES.ERROR;

	return actionStart;
}

export function create(actionTypePrefix, action, options) {
	const ACTION_TYPES = Object.freeze({
		START: 'redux-units/' + actionTypePrefix,
		SUCCESS: 'redux-units/' + actionTypePrefix + '_SUCCESS',
		ERROR: 'redux-units/' + actionTypePrefix + '_ERROR',
	});

	const initialState = {
		isLoading: false,
		error: null,
		...((options && options.initialPayload) || {}),
	};

	function reducer(state = initialState, action) {
		switch (action.type) {
			case ACTION_TYPES.START:
				return {
					...state,
					isLoading: true,
				};

			case ACTION_TYPES.SUCCESS:
				return {
					...state,
					isLoading: false,
					error: null,
					...action.payload,
				};

			case ACTION_TYPES.ERROR:
				return {
					...state,
					isLoading: false,
					error: action.error,
				};

			default:
				if (options && options.additionalReducer) {
					return options.additionalReducer(state, action);
				}

				return state;
		}
	}

	function actionStart(...args) {
		return {
			type: ACTION_TYPES.START,
			args,
		};
	}

	function actionSuccess(payload) {
		return {
			type: ACTION_TYPES.SUCCESS,
			payload,
		};
	}

	function actionError(error) {
		return {
			type: ACTION_TYPES.ERROR,
			error,
		};
	}

	function* runAction({ args }) {
		yield action(actionSuccess, actionError, ...args);
	}

	function* saga() {
		yield takeLatest(ACTION_TYPES.START, runAction);
	}

	actionStart.reducer = reducer;
	actionStart.saga = saga;
	actionStart.START = ACTION_TYPES.START;
	actionStart.SUCCESS = ACTION_TYPES.SUCCESS;
	actionStart.ERROR = ACTION_TYPES.ERROR;

	return actionStart;
}

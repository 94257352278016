import { takeLatest, delay, call, put, all } from 'redux-saga/effects';

import {
	USERS_LOGIN,
	loginSuccess as usersLoginSuccess,
	loginError as usersLoginError,
	USERS_ME,
	meSuccess as usersMeSuccess,
	meError as usersMeError,
} from './../actions/users';

import * as remote from './../../remote';

function* login({ payload: { email, password } }) {
	try {
		const response = yield call(remote.login, email, password);
		yield put(usersLoginSuccess(response.data));

		yield delay(10);
		yield* me();
	} catch (e) {
		yield put(usersLoginError('Login failed'));
	}
}

export function* me() {
	try {
		const response = yield call(remote.me);
		yield put(usersMeSuccess(response.data));
	} catch (e) {
		yield put(usersMeError(e));
	}
}

export default function* usersSaga() {
	yield all([takeLatest(USERS_LOGIN, login), takeLatest(USERS_ME, me)]);
}
